import React, { Component, Fragment } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import data_types from '../data/data_types';
import './nx_grid.scss';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import NotesIcon from '@mui/icons-material/Notes';
import withRouter from './withRouter';
import Nx_Grid_Popover from './nx_grid_popover';
import Grid from '../ui_components/layouts/Grid/Grid';
import moment from 'moment';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NxIconButton from '../ui_components/controls/nx_icon_button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LinearProgress from '@mui/material/LinearProgress';

class Nx_Grid extends Component {
   constructor(props) {
      super(props);

      this.state = {
         data: [],
         columns: [],
         page: 0,
         pageSize: 10,
         totalItems: 0,
         isLoading: true,
         sortField: [],
         popoverActive: false,
         activeRows: [],
         rowHeight: 63
      };
      this.fields = props.fields;
      this.config = props.config
         ? props.config
         : props.fields
         ? props.fields.map((field) =>
              typeof field == 'string' ? global.UF.data_structure[props.table].fields[field] : field
           )
         : global.UF.data_structure[props.table].fieldsArr;

      this.lastUpdate = props.lastUpdate;

      this.references = {
         count: 0,
         loaded: 0,
         arr: []
      };
   }

   capitalizeFirstLetter = (string) => {
      if (string.length) {
         return string.charAt(0).toUpperCase() + string.slice(1);
      }
   };

   generateHeaderName = (item) => {
      if (item.hasOwnProperty('gridLabel') && item.gridLabel) {
         if (item.gridLabel.includes('_')) {
            return `${this.capitalizeFirstLetter(item.gridLabel.split('_')[0])} ${this.capitalizeFirstLetter(
               item.gridLabel.split('_')[1]
            )}`;
         } else {
            return this.capitalizeFirstLetter(item.gridLabel);
         }
      } else {
         if (item.label.includes('_')) {
            return `${this.capitalizeFirstLetter(item.label.split('_')[0])} ${this.capitalizeFirstLetter(
               item.label.split('_')[1]
            )}`;
         } else {
            return this.capitalizeFirstLetter(item.label);
         }
      }
   };

   componentDidMount = () => {
      this.state.sortField = Array.isArray(global.UF.data_structure[this.props.table].sorting)
         ? global.UF.data_structure[this.props.table].sorting
         : global.UF.data_structure[this.props.table].fieldsArr.findIndex((field) => field.Field === 'created_at') > -1
         ? [
              {
                 field: 'created_at',
                 sort: 'desc'
              }
           ]
         : [];
      this.props.syncData(this.fetchData);
      this.fetchReferences(true);
   };

   fetchReferences = (force = false, filters = this.props.filters) => {
      if (this.references.count > 0) {
         this.references.loaded = 0;
         this.references.arr.forEach((ref) => {
            global.UF.dataProvider.referenceProvider.get(
               ref,
               (data) => {
                  this.references[ref] = {};
                  data.forEach((item) => (this.references[ref][item.id] = item.label));
                  this.references.loaded++;
                  if (this.references.loaded == this.references.count) {
                     this.fetchData(filters);
                  }
               },
               force
            );
         });
      } else {
         this.fetchData(filters);
      }
   };

   handleSortModelChange = (sortField) => {
      this.setState({ sortField }, this.fetchData);
   };

   renderButtons = (params) => {
      if (this.props.buttons) {
         return Object.keys(this.props.buttons).map((button, key) => {
            return <span key={key}>{this.props.buttons[button](params, this.fetchData)}</span>;
         });
      } else {
         return null;
      }
   };

   handleToolTipText = (additional_data, row) => {
      return additional_data.map((field, key) => {
         if (row.hasOwnProperty(field)) {
            if (row[field] !== null && row[field] !== undefined) {
               return (
                  <div key={key}>
                     {this.capitalizeFirstLetter(field)} : {row[field].length > 0 ? row[field] : 'Not defined'}
                  </div>
               );
            } else {
               return (
                  <div key={key}>
                     {this.capitalizeFirstLetter(field)} : {'Not defined'}
                  </div>
               );
            }
         }
      });
   };

   handleColumns = () => {
      const columns = this.config.map((c, key) => {
         const config = {
            ...c,
            field: c.field,
            className: 'something',
            headerAlign: 'center',
            headerName: this.generateHeaderName(c),
            fontSize: '12px',
            flex: c.flex ? c.flex : 1,
            align: c.align ? c.align : 'center',
            width: c.width ? c.width : 'auto'
         };
         switch (c.datatype) {
            case data_types.boolean:
            case data_types.color:
            case data_types.number:
               config['align'] = 'center';
               break;
            case data_types.date:
            case data_types.datetime:
            case data_types.time:
            case data_types.reference:
               config['align'] = 'center';
               break;
         }

         // config["align"] = c.align ? c.align : "left";

         if (
            (c.datatype == data_types.reference || c.datatype == data_types.autocomplete) &&
            this.references.hasOwnProperty(c.reference) == false
         ) {
            this.references[c.reference] = null;
            this.references.count++;
            this.references.arr.push(c.reference);
         }

         if (c.hasOwnProperty('renderCell') == false) {
            if (c.datatype == data_types.color) {
               config['renderCell'] = (params) => {
                  if (params['value'] != null) {
                     return (
                        <Tooltip title={`${params['value']}`} key={key}>
                           <div
                              key={key}
                              style={{
                                 backgroundColor: `${params['value']}`,
                                 width: '15px',
                                 height: '15px',
                                 borderRadius: '30%'
                              }}
                           />
                        </Tooltip>
                     );
                  } else {
                     return (
                        <Tooltip title={`-`} key={key}>
                           <div key={key}>-</div>
                        </Tooltip>
                     );
                  }
               };
            }

            if (c.datatype == data_types.time) {
               config['renderCell'] = (params) => {
                  if (params['value'] != null) {
                     return (
                        <Tooltip
                           key={key}
                           title={`${moment(params['value']).format('DD/MM/YYYY')}, ${moment(params['value']).format(
                              'HH:mm:ss'
                           )}`}
                        >
                           <div style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
                              {`${moment(params['value']).format('DD/MM/YYYY')}\n${moment(params['value']).format(
                                 'HH:mm:ss'
                              )}`}
                           </div>
                        </Tooltip>
                     );
                  } else {
                     return (
                        <Tooltip key={key} title={'-'}>
                           <div>-</div>
                        </Tooltip>
                     );
                  }
               };
            }

            if (c.datatype == data_types.date) {
               config['renderCell'] = (params) => {
                  if (params['value'] != null) {
                     return (
                        <Tooltip key={key} title={`${moment(params['value']).format('DD/MM/YYYY')}`}>
                           <div style={{ whiteSpace: 'pre-line' }}>
                              {`${moment(params['value']).format('DD/MM/YYYY')}`}
                           </div>
                        </Tooltip>
                     );
                  } else {
                     return (
                        <Tooltip key={key} title={'-'}>
                           <div>-</div>
                        </Tooltip>
                     );
                  }
               };
            }

            if (c.datatype == data_types.boolean) {
               config['renderCell'] = (params) => {
                  if (params['value'] !== null) {
                     return (
                        <Tooltip key={key} title={params['value'] === 1 ? 'Yes' : 'No'}>
                           <div
                              style={{
                                 color: `${params['value'] === 1 ? '#82D73F' : '#EC5F5F'}`
                              }}
                           >
                              {params['value'] === 1 ? 'Yes' : 'No'}
                           </div>
                        </Tooltip>
                     );
                  } else {
                     return (
                        <Tooltip key={key} title={'-'}>
                           <div>{'-'}</div>
                        </Tooltip>
                     );
                  }
               };
            }

            if (c.datatype == data_types.reference || c.datatype == data_types.autocomplete) {
               config['renderCell'] = (params) => {
                  if (params['value'] !== null) {
                     return (
                        <Tooltip
                           key={key}
                           title={
                              params.value && this.references[c.reference] && this.references[c.reference][params.value]
                           }
                        >
                           <div>
                              {params.value &&
                                 this.references[c.reference] &&
                                 this.references[c.reference][params.value]}
                           </div>
                        </Tooltip>
                     );
                  } else {
                     return (
                        <Tooltip key={key} title={'-'}>
                           <div>{'-'}</div>
                        </Tooltip>
                     );
                  }
               };
            }
         } else if (c.hasOwnProperty('renderCell')) {
            const tempRenderCell = c.renderCell;
            c.renderCell = (params) => {
               if (params.value != null) {
                  if (typeof params.value == 'string') {
                     if (params.value && params.value.length) {
                        return c.datatype == data_types.reference || c.datatype == data_types.autocomplete
                           ? tempRenderCell(params, this.references[c.reference])
                           : tempRenderCell(params);
                     } else {
                        return (
                           <Tooltip title={'-'}>
                              <div>-</div>
                           </Tooltip>
                        );
                     }
                  } else {
                     return tempRenderCell(params);
                  }
               } else {
                  return (
                     <Tooltip title={'-'}>
                        <div>-</div>
                     </Tooltip>
                  );
               }
            };
         }

         if (c.hasOwnProperty('expandable') && c.expandable && c.hasOwnProperty('expandableContent')) {
            c.flex = 2;
            c.renderCell = (params) => {
               if (c.expandableContent(params.row) == null) {
                  return <div>{params.value}</div>;
               }
               return (
                  <div
                     style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                     }}
                  >
                     <NxIconButton onClick={() => this.handleExpand(params)}>
                        {params.row.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                     </NxIconButton>
                     {params.row.expanded ? c.expandableContent(params.row) : params.value}
                  </div>
               );
            };
         }
         return config;
      });
      if (global.UF.data_structure[this.props.table].additionalData) {
         const additionalData = global.UF.data_structure[this.props.table].additionalData;
         additionalData.forEach((objInConfig) => {
            let currentColumn = columns.find((col) => col.field == objInConfig);
            currentColumn.hide = true;
         });
         columns.push({
            headerName: 'Additional Data',
            field: 'additional_data',
            align: 'center',
            flex: 1,
            sortable: false,
            width: 'auto',
            renderCell: (params) => {
               if (params.row.notes !== null && params.row.notes.trim() !== '') {
                  return (
                     <Tooltip title={this.handleToolTipText(additionalData, params.row)}>
                        <NotesIcon />
                     </Tooltip>
                  );
               } else {
                  return <div />;
               }
            }
         });
      }
      if (this.props.buttons && Object.keys(this.props.buttons).length) {
         columns.push({
            field: ' ',
            align: 'center',
            width: 'max-content',
            sortable: false,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (params) => {
               return <>{this.renderButtons(params)}</>;
            }
         });
      }

      return columns;
   };

   handleExpand = (params) => {
      if (!params.row.hasOwnProperty('expanded')) {
         params.row.expanded = true;
         this.forceUpdate();
         return;
      }

      params.row.expanded = !params.row.expanded;
      this.forceUpdate();
   };

   handleCellClick = (params) => {
      const ds = global.UF.data_structure[this.props.table];
      const fieldName = params.field;
      const currentField = ds.fieldsArr.find((field) => field.Field == fieldName);
      if (currentField && currentField.hasOwnProperty('onClick')) {
         currentField.onClick(params.row);
      }
   };

   shouldComponentUpdate = (nextProps, nextState) => {
      if (this.props.router.location.pathname != nextProps.router.location.pathname) {
         this.fetchData(nextProps.filters);
      }
      if (nextState.activeRows.length) {
         if (nextState.activeRows.length == 1) {
            nextProps.popOverButtons.edit = () => {
               return (
                  <Grid style={{ justifyContent: 'center', alignItems: 'center' }}>
                     <IconButton onClick={() => nextProps.onEdit(nextState.activeRows[0])} size={'large'}>
                        <EditIcon fontSize={'large'} sx={{ color: '#2D5DFCB2' }} />
                     </IconButton>
                  </Grid>
               );
            };
         } else {
            if (nextProps.popOverButtons.edit) {
               delete nextProps.popOverButtons.edit;
            }
         }
      }

      if (this.config.length != nextProps.fields.length) {
         this.config = nextProps.config
            ? nextProps.config
            : nextProps.fields
            ? nextProps.fields.map((field) =>
                 typeof field == 'string' ? global.UF.data_structure[nextProps.table].fields[field] : field
              )
            : global.UF.data_structure[nextProps.table].fieldsArr;
      }
      if (this.props.region_id != nextProps.region_id) {
         this.fetchData(nextProps.filters);
      }

      return true;
   };

   handleRegionsForUser = (qs) => {
      if (Array.isArray(global.UF.region_id)) {
         if (qs.hasOwnProperty('custom_filter')) {
            qs['custom_filter'] += ` AND (`;
         } else {
            qs['custom_filter'] = ' ( ';
         }
         global.UF.region_id.forEach((region_id, index) => {
            qs[
               'custom_filter'
            ] += `FIND_IN_SET('"${region_id}"', REPLACE(REPLACE(REPLACE(region_id, '[', ''), ']', ''), ' ', '')) > 0 ${
               index + 1 == global.UF.region_id.length ? '' : ' OR '
            }`;
         });

         qs['custom_filter'] += ' OR region_id IS NULL )';
      } else {
         qs[
            'custom_filter'
         ] = ` FIND_IN_SET('"${global.UF.region_id}"', REPLACE(REPLACE(REPLACE(region_id, '[', ''), ']', ''), ' ', '')) > 0`;
      }
      return qs;
   };

   handleRegionsForKT = async () => {
      const regions = await global.UF.dataProvider.get_v2(`regions`, {});
      return regions.map((region) => region.id);
   };

   fetchData = async (filters = this.props.filters) => {
      if (this.state.isLoading == false) {
         this.setState({ isLoading: true });
      }

      let qs = Object.assign(
         {
            page: this.state.page,
            size: this.state.pageSize
         },
         filters ? filters : {}
      );

      if (this.props.customFiltering) {
         this.props.customFiltering(qs);
      }

      const ds = global.UF.dataProvider.datastructure[this.props.table];

      if (ds.fields.hasOwnProperty('region_id') && global.UF.region_id) {
         if (this.props.table != 'users') {
            if (this.props.user?.has_kt && Array.isArray(global.UF.region_id)) {
               qs['region_id'] = await this.handleRegionsForKT();
            } else {
               qs['region_id'] = global.UF.region_id;
            }
         } else {
            delete qs['region_id'];
            qs = this.handleRegionsForUser(qs);
         }
      }

      if (ds.hasOwnProperty('filters') && ds.filters) {
         Object.keys(ds.filters).forEach((filter) => {
            qs[filter] = ds.filters[filter];
         });
      }

      if (this.state.sortField.length > 0) {
         qs.order_by = this.state.sortField[0].field;
         qs.order = this.state.sortField[0].sort;
      }

      Object.keys(qs).forEach((key) => {
         if (Array.isArray(qs[key]) && key != 's') {
            qs[key] = JSON.stringify(qs[key]);
         }
      });

      if (this.props.syncData) {
         this.props.syncData(this.fetchData, qs);
      }

      global.UF.dataProvider.get(this.props.table, qs, async (data, totalItems) => {
         if (this.props.onDataItemsLoad) {
            await this.props.onDataItemsLoad(data, () => {
               this.setState({ data, totalItems, isLoading: false });
            });
         } else {
            this.setState({ data, totalItems, isLoading: false });
         }
      });
   };

   handleSelectionModelChange = (rowsIds) => {
      const rows = rowsIds.map((id) => this.state.data.find((row) => row.id === id));
      if (rows.length) {
         this.setState({ popoverActive: true, activeRows: rows });
      } else {
         this.setState({ popoverActive: false, activeRows: rows });
      }
   };

   componentWillReceiveProps = (props) => {
      if (props.lastUpdate != this.lastUpdate) {
         this.lastUpdate = props.lastUpdate;
         this.fetchReferences(true, props.filters);
      }
   };

   renderPopOver = () => {
      return (
         <Nx_Grid_Popover
            show={this.state.popoverActive}
            buttons={this.props.popOverButtons}
            rows={this.state.activeRows}
            fetchData={this.fetchData}
         />
      );
   };

   handleRowClick = (params, event) => {
      if (event.ignore) {
         return;
      }

      if (this.props.onRowClick) {
         this.props.onRowClick(params);
      }
   };

   render() {
      return (
         <div style={{ width: '100%', height: '100%' }}>
            {this.state.popoverActive && this.renderPopOver()}
            <DataGrid
               sx={{
                  '& .MuiDataGrid-cell': {
                     whiteSpace: 'normal !important',
                     textAlign: 'center !important',
                     textOverflow: 'ellipsis !important'
                  },
                  '& .MuiDataGrid-row': {
                     maxHeight: 'unset !important',
                     height: 'auto'
                  },
                  '& .MuiDataGrid-iconButtonContainer': {
                     width: 'auto !important'
                  },
                  '.MuiDataGrid-sortIcon': {
                     display: 'none !important'
                  },
                  '& .MuiDataGrid-row:hover': {
                     cursor: this.props.onRowClick ? 'pointer' : 'auto'
                  }
               }}
               rows={this.state.data}
               rowHeight={this.props.rowHeight ? this.props.rowHeight : this.state.rowHeight}
               getRowHeight={() => (this.props.rowHeightAuto ? 'auto' : this.props.rowHeight)}
               getRowId={(row) => {
                  const table_structure = row['table_structure'];
                  const id = table_structure.hasOwnProperty('id') && table_structure.id ? table_structure.id : 'id';

                  if (Array.isArray(id)) {
                     const values = id
                        .map((i) => {
                           return row[i];
                        })
                        .join('-');
                     return values;
                  }

                  return row[id];
               }}
               components={{
                  LoadingOverlay: function Component(props) {
                     return <LinearProgress />;
                  }
               }}
               loading={this.state.isLoading}
               columns={this.handleColumns()}
               rowCount={this.state.totalItems}
               page={this.state.page}
               pageSize={this.state.pageSize}
               paginationMode="server"
               onPageChange={(newPage) => {
                  this.state.page = newPage;
                  this.fetchData();
               }}
               onPageSizeChange={(newPageSize) => {
                  this.state.pageSize = newPageSize;
                  this.fetchData();
               }}
               rowReordering
               initialState={{
                  sorting: {
                     sortModel: global.UF.data_structure[this.props.table].hasOwnProperty('sorting')
                        ? global.UF.data_structure[this.props.table]['sorting']
                        : this.state.sortField
                  }
               }}
               onCellClick={this.handleCellClick}
               checkboxSelection={false}
               disableSelectionOnClick
               onSelectionModelChange={(items) => this.handleSelectionModelChange(items)}
               onRowClick={this.handleRowClick}
               onRowDoubleClick={this.props.rowDoubleClick ? (params) => this.props.rowDoubleClick(params) : () => {}}
               rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
               disableColumnMenu
               sortingMode="server"
               onSortModelChange={this.handleSortModelChange}
               className="nx_grid_wrapper"
            />
         </div>
      );
   }
}

export default withRouter(Nx_Grid);
