import React from 'react';
import UF_Content from '../../../nix_components/components/UF_Content';
import withRouter from '../../../nix_components/components/withRouter';
import NxIconButton from '../../../nix_components/ui_components/controls/nx_icon_button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Nx_Dialog from '../../../nix_components/ui_components/dialogs/nx_dialog';
import NxGridView from '../../../nix_components/components/NxGridView';

class FirstCheckup extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         active_record: null,
         table: this.props.transfered ? 'view_transfered_patients_book' : 'view_patients_book'
      };

      global.UF.dataProvider.datastructure[this.state.table].filters = {
         subject_type: ['first_check_up', 'archived'],
         custom_filter: `(ordinal_number not like "%Р%" and ordinal_number not like "%P%")`,
         order_by:
            "CAST(SUBSTRING_INDEX(SUBSTRING_INDEX(REPLACE(ordinal_number, ' ', ''), '/', 1), '-', -1) AS UNSIGNED)",
         order: 'ASC'
      };
   }

   buttons = {
      showWholeRecord: (params) => {
         return (
            <NxIconButton
               tooltip={'Прегледај деловодник'}
               color="default"
               onClick={() => this.setState({ active_record: params.row })}
            >
               <VisibilityIcon />
            </NxIconButton>
         );
      }
   };

   formConfig = {
      rows: [['ordinal_number', {}], ['note']]
   };

   viewConfig = {
      rows: [
         ['ordinal_number', 'fullname'],
         ['date_of_birth', 'address'],
         ['id_number', 'responsible_person'],
         ['date_of_assessment', 'contact_name'],
         ['date_of_reassessment', {}],
         ['note', 'diagnosis'],
         ['education', 'recommendations']
      ]
   };

   viewButtons = [
      {
         label: 'Затвори',
         variant: 'text',
         onClick: () => this.setState({ active_record: null })
      }
   ];

   gridProps = {
      fields: ['ordinal_number', 'fullname', 'diagnosis_tag', 'recommendations', 'note']
   };

   onSave = async (dataItem, callback) => {
      const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
         id: dataItem.subject_id
      });
      if (!subjects || !subjects.length) {
         global.UF.setAlertVisibility('Не постоји предмет са тим ИД!');
         return;
      }

      const subject = subjects[0];
      subject.note = dataItem.note;
      subject.delovoden_broj = dataItem.ordinal_number;
      subject.Save(() => {
         if (callback) {
            callback();
         }
      });
   };

   handleRowClick = (row) => {
      const dataItem = row.row;
      this.props.router.navigate(`/patients/${dataItem.patient_id}`);
   };

   renderWholeRecord = () => {
      return (
         <Nx_Dialog open={true} buttons={this.viewButtons} handleClose={() => this.setState({ active_record: null })}>
            <NxGridView
               dataItem={this.state.active_record}
               table={this.state.table}
               title={'Деловодник'}
               viewConfig={this.viewConfig}
               buttons={[]}
            />
         </Nx_Dialog>
      );
   };

   handleTags = (item) => {
      const temp_arr_diagnosis = [];
      if (item.diagnosis_tag) {
         const regex = /\[\[.*?\]\]/g;
         const matches = item.diagnosis_tag.match(regex);
         if (matches?.length) {
            matches.forEach((match) => {
               const parsed_match = JSON.parse(match);
               temp_arr_diagnosis.push(parsed_match[0][0].id);
            });
         }
      }
      item.formated_diagnosis_tag = temp_arr_diagnosis;
   };

   onDelete = async (dataItem) => {
      try {
         const response = await global.UF.dataProvider.get_v2(`subjects`, {
            id: dataItem.subject_id
         });

         if (!response || response.length == 0) {
            global.UF.handleError(`Предмет со ИД : ${dataItem.subject_id} не е пронадјен`);
            return;
         }

         const subject = response[0];

         subject.Delete(() => {});
      } catch (err) {
         global.UF.handleError(err);
      }
   };

   onDataItemsLoad = (data, callback) => {
      if (!data.length) {
         if (callback) {
            callback();
         }
      }

      data.forEach((item) => {
         this.handleTags(item);
      });

      if (callback) {
         callback();
      }
   };

   render() {
      return (
         <>
            {this.state.active_record && this.renderWholeRecord()}
            <UF_Content
               table={this.state.table}
               helper="Пример реден бр. : П01-001/2021"
               title="Преглед и управување со деловодна книга"
               formTitle="Деловодник"
               gridProps={this.gridProps}
               buttons={this.buttons}
               delete={false}
               edit={this.props.hasOwnProperty('edit') ? this.props.edit : true}
               onSave={this.onSave}
               onRowClick={this.handleRowClick}
               formConfig={this.formConfig}
               onDataItemsLoad={this.onDataItemsLoad}
               onDelete={this.onDelete}
               rowHeightAuto={true}
               add={false}
               {...this.props}
            />
         </>
      );
   }
}

export default withRouter(FirstCheckup);
