import React from 'react';
import './appeal.scss';
import withRouter from '../../../nix_components/components/withRouter';
import Grid from '../../../nix_components/ui_components/layouts/Grid/Grid';
import Nx_Button from '../../../nix_components/ui_components/controls/nx_button';
import SubjectSelect from './components/SubjectSelect';
import SubjectTag from './components/SubjectTag';
import SubjectApproveButton from './components/SubjectApproveButton';
import SubjectInput from './components/SubjectInput';
import DeleteItemModal from '../../../components/modals/DeleteItemModal';
import { usersTypes } from '../../../nix_components/data/DataReferences';
import { subjectStatuses } from '../../../nix_components/data/DataReferences';
import moment from 'moment';

class Appeal extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         id: null,
         subject_type: null,
         deleteItem: null,
         patient_id: null,
         is_new: false,
         subject: null,
         not_editable: false,
         initializing: true,
         willUnmount: false,
         subject_details: {},
         original_subject_details: {},
         social_worker: {},
         psychologist: {},
         special_educator_and_rehabilitator: {},
         doctor: {},
         regions: {},
         patients: {},
         view_patients_last_subject: {},
         read_only: false,
         is_printed: false,
         has_kt: false
      };
   }

   handleKT = async () => {
      const { user } = this.props;
      if (!user) {
         return;
      }

      if (usersTypes[user.user_type_id] == 'super_admin') {
         this.setState({ has_kt: true });
         return;
      }

      let region_id = user.region_id;
      region_id = region_id && typeof region_id == 'string' ? JSON.parse(region_id) : region_id;

      const regions = await global.UF.dataProvider.get_v2(`regions`, {
         id: region_id
      });

      if (!regions.length) {
         global.UF.setAlertVisibility(true, 'Не постои регион со тој ИД број!', 'warning');
         return;
      }

      const hasKT = regions.find((r) => r.code == 'КТ');

      if (hasKT) {
         this.setState({ has_kt: hasKT });
      }
   };

   handleInitialization = async (params) => {
      const patient_id = params.patient_id;
      const id = params.id;
      const subject_type = params.type;
      const patients = await global.UF.dataProvider.get_v2(`patients`, {
         id: patient_id
      });

      if (!patients.length) {
         global.UF.setAlertVisibility(true, 'Не постои пациент со тој ИД број!', 'warning');
         return;
      }

      await this.handleKT();

      const view_patients_last_subjects = await global.UF.dataProvider.get_v2(`view_patients_last_subject`, {
         patient_id: patient_id
      });

      const view_patients_last_subject = view_patients_last_subjects.length ? view_patients_last_subjects[0] : {};
      const patient = patients[0];

      const regions = await global.UF.dataProvider.get_v2(`regions`, {
         code: 'КТ'
      });

      if (!regions.length) {
         global.UF.setAlertVisibility(true, 'Не постоји регион са овим ИД', 'warning');
         return;
      }

      const region = regions[0];

      if (id) {
         const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
            id: id
         });
         if (!subjects.length) {
            global.UF.setAlertVisibility(true, 'Не постои пациент со тој ИД број!', 'warning');
            return;
         }
         let subject_details = await global.UF.dataProvider.get_v2(`subject_details`, { subject_id: id });
         const parsedObj = {};
         subject_details.forEach((sd) => {
            parsedObj[sd['subject_key']] = sd['value_str'];

            if (sd[['value_multiple_ref']]) {
               parsedObj[sd['subject_key']] = sd['value_multiple_ref'];
            }

            if (sd[['value_single_ref']]) {
               parsedObj[sd['subject_key']] = sd['value_single_ref'];
            }

            if (sd['value_tag']) {
               parsedObj[sd['subject_key']] = sd['value_tag'];
            }

            if (sd['value_int']) {
               parsedObj[sd['subject_key']] = sd['value_int'];
            }
         });

         const split = subjects[0]?.delovoden_broj?.split('-');

         let region_subjects = [];

         if (split.length) {
            const region_code = split[1]?.replaceAll(' ', '');

            region_subjects = await global.UF.dataProvider.get_v2(`regions`, { code: region_code });
         }

         this.setState({
            subject_details: { ...parsedObj },
            original_subject_details: { ...parsedObj },
            id: id,
            subject_type: subject_type,
            is_new: false,
            patient_id: patient_id,
            subject: subjects[0],
            region_subject: region_subjects.length ? region_subjects[0] : null,
            not_editable: subjects[0].status !== 'in_progress' && subjects[0].status !== 'approved',
            patients: patient,
            view_patients_last_subject: view_patients_last_subject,
            regions: region
         });
      } else {
         this.props.router.navigate(`/patients/${patient_id}`);
      }
   };

   fetchAllNecesseryRefs = async () => {
      let ref_keys = await global.UF.dataProvider.get_v2(`subject_keys`, {
         type: ['ss', 'tag', 'ms']
      });

      if (!ref_keys.length) {
         global.UF.setAlertVisibility(true, 'There are no references to fetch!', 'info');
         this.setState({ initializing: false });
      }

      const uniqueTypes = new Set();
      const unique_refs = ref_keys.filter((obj) => !uniqueTypes.has(obj.ref) && uniqueTypes.add(obj.ref));

      unique_refs.forEach((ref_key, index) => {
         const reference_type_and_reference = ref_key.ref;
         let reference = reference_type_and_reference.split('.')[1];
         let filters = {};
         if (reference.includes(':')) {
            let split = reference.split(':');
            filters = split[1];
            reference = split[0];
            filters = filters.split('=');
            const temp_filters = {};
            if (reference === 'users') {
               filters[1] = filters[1].split(',');
            }
            temp_filters[filters[0]] = filters[1];
            filters = temp_filters;
         }

         if (reference === 'users') {
            filters['region_id'] = this.state.patients.region_id;
         }

         global.UF.dataProvider.referenceProvider.get(
            reference,
            (data) => {
               if (index + 1 === unique_refs.length) {
                  this.setState({ initializing: false });
               }
            },
            true,
            filters
         );
      });
   };

   handleWholeInitialization = async (router) => {
      const { params } = router;
      await this.handleInitialization(params);
      await this.fetchSpecialistAndDoctors();
      await this.fetchAllNecesseryRefs();
   };

   componentDidMount = async () => {
      this.handleWholeInitialization(this.props.router);
   };

   shouldComponentUpdate = async (nextProps) => {
      const params = this.props.router.params;
      const nextParams = nextProps.router.params;

      if (params.hasOwnProperty('id') && !nextParams.hasOwnProperty('id')) {
         this.props.router.navigate(`/patients/${params.patient_id}`);
         return true;
      }

      if (JSON.stringify(params) !== JSON.stringify(nextParams)) {
         await this.handleWholeInitialization(nextProps.router);
      }

      return true;
   };

   handleMultipleReferences = async (subject_details_arr, callback) => {
      if (!subject_details_arr.length) {
         callback();
         return;
      }

      const multiple_refs = subject_details_arr.filter(
         (item) => Array.isArray(item.value) && typeof item.value !== 'string'
      );

      if (!multiple_refs.length) {
         callback();
         return;
      }

      const ref_keys = multiple_refs.map((ref) => ref.key);

      const subject_keys = await global.UF.dataProvider.get_v2(`subject_keys`, {
         key: ref_keys
      });

      subject_keys.forEach((subject_key, index) => {
         const split = subject_key.ref.split('.');
         const reference_with_filter = split[1];
         let reference = '';
         if (reference_with_filter.includes(':')) {
            reference = reference_with_filter.split(':')[0];
         } else {
            reference = reference_with_filter;
         }

         const current_ref = multiple_refs.find((ref) => ref.key === subject_key.key);
         if (!current_ref) {
            console.error(`Current ref not found!`);
            return;
         }

         const value = current_ref.value;
         current_ref.valueMultiRef = [];

         global.UF.dataProvider.referenceProvider.get(
            reference,
            (data) => {
               current_ref.valueMultiRef = data
                  .filter((item) => value.includes(item.id))
                  .map((item) => item.label)
                  .join(',');
               if (index + 1 === subject_keys.length) {
                  if (callback) {
                     callback();
                  }
               }
            },
            false
         );
      });
   };

   handleSingleReferences = async (subject_details_arr, callback) => {
      if (!subject_details_arr.length) {
         callback();
      }

      const keys = subject_details_arr.map((item) => item.key);

      const single_refs_details = await global.UF.dataProvider.get_v2(`subject_keys`, {
         key: keys,
         type: 'ss'
      });

      if (!single_refs_details.length) {
         callback();
      }

      const single_refs_keys = single_refs_details.map((ref_key) => ref_key.key);

      const single_refs = subject_details_arr.filter((item) => {
         if (single_refs_keys.includes(item.key)) {
            item.reference = single_refs_details.find((key_detail) => key_detail.key === item.key).ref;
            return true;
         }
         return false;
      });

      if (!single_refs.length) {
         callback();
      }

      single_refs.forEach((ref, index) => {
         let reference = '';
         reference = ref.reference.split('.')[1];
         if (reference.includes(':')) {
            const split = reference.split(':');
            reference = split[0];
         }

         global.UF.dataProvider.referenceProvider.get(
            reference,
            (data) => {
               const valueSingleRef = data.find((item) => item.id == ref.value);
               ref.valueSingleRef = valueSingleRef.label;

               if (single_refs.length == index + 1) {
                  if (callback) {
                     callback();
                  }
               }
            },
            false
         );
      });
   };

   handleTags = async (subject_details, callback) => {
      if (!subject_details.length) {
         callback();
      }

      const keys = subject_details.map((sd) => sd.key);

      const tags_details_keys = await global.UF.dataProvider.get_v2(`subject_keys`, {
         key: keys,
         type: 'tag'
      });

      if (!tags_details_keys?.length) {
         if (callback) {
            callback();
         }
      }

      const tags_keys = tags_details_keys.map((ref_key) => ref_key.key);

      const tags_keys_details = subject_details.filter((item) => {
         if (tags_keys.includes(item.key)) {
            return true;
         }
         return false;
      });

      tags_keys_details.forEach((detail) => {
         const regex = /\[\[.*?\]\]/g;
         const matches = detail.value.match(regex);
         detail.valueTag = '';
         let tempValue = detail.value;

         if (matches && matches.length) {
            matches.forEach((match) => {
               const parsed_match = JSON.parse(match);
               tempValue = tempValue.replace(match, parsed_match[0][0].value);
            });
            detail.valueTag = tempValue;
         }
      });
      if (callback) {
         callback();
      }
   };

   returningOnlyChangedFields = () => {
      let temp_subject_details = {};

      Object.keys(this.state.subject_details).forEach((key) => {
         if (this.state.original_subject_details[key] == undefined) {
            temp_subject_details[key] = this.state.subject_details[key];
         }

         if (this.state.original_subject_details[key] != this.state.subject_details[key]) {
            temp_subject_details[key] = this.state.subject_details[key];
         }
      });

      return temp_subject_details;
   };

   handleSavingSubject = async (redirect = true) => {
      const changed_subject_details = this.returningOnlyChangedFields();

      const db_fields = await global.UF.dataProvider.get_v2(`subject_keys`, {
         type: 'db'
      });

      const db_obj = {};
      db_fields.forEach((db_field) => {
         const split = db_field.key.split('.');
         const table = split[0];
         const field = split[1];
         if (
            [
               'psychologist.specialist_type',
               'social_worker.specialist_type',
               'special_educator_and_rehabilitator.specialist_type'
            ].includes(db_field.key)
         ) {
            db_obj[db_field.key] = global.UF.tr(this.state[table][field]);
         } else {
            db_obj[db_field.key] = this.state[table][field];
         }
      });

      const full_subject_details = Object.assign(changed_subject_details, db_obj);

      const subject_details_arr = Object.entries(full_subject_details).map(([key, value]) => {
         return { key, value };
      });

      await this.handleSingleReferences(subject_details_arr, async () => {
         await this.handleMultipleReferences(subject_details_arr, async () => {
            await this.handleTags(subject_details_arr, async () => {
               try {
                  await global.UF.makeRequest_v2(
                     `POST`,
                     `/api/subjects/save`,
                     {
                        subject_details: subject_details_arr,
                        subject_id: this.state.id
                     },
                     true
                  );
                  // if (redirect) {
                  //   this.props.router.navigate(`/patients/${this.state.patient_id}`);
                  // }
               } catch (err) {
                  global.UF.handleError(err);
                  return;
               }
            });
         });
      });

      return;
   };

   fetchSpecialistAndDoctors = async () => {
      const { id } = this.props.router.params;
      const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
         id: id
      });

      if (!subjects.length) {
         global.UF.setAlertVisibility(true, 'Нема предмета са тим ИД', 'info');
         return;
      }

      const subject = subjects[0];

      const temp_fields = ['social_worker', 'psychologist', 'special_educator_and_rehabilitator', 'doctor'];

      for (const field of temp_fields) {
         const id = subject[`${field}_id`];
         if (id) {
            const users = await global.UF.dataProvider.get_v2(`users`, {
               id: subject[`${field}_id`]
            });

            this.state[field] = users[0];
         }
      }

      // let read_only = true;
      // for (const field of temp_fields) {
      //   if (!this.state[field] || Object.keys(this.state[field]).length == 0) {
      //     read_only = false;
      //     break;
      //   }
      // }

      if (!this.state.has_kt) {
         this.setState({ read_only: true });
         return;
      }

      this.setState({ read_only: false });
   };

   handleUpdatePreviousSubjectNumberRevaluation = async () => {
      const keys = Object.keys(this.state.view_patients_last_subject);
      if (!keys.length) {
         return;
      }
      const subject_id = this.state.view_patients_last_subject.id;
      const delovoden_broj = this.state.view_patients_last_subject.delovoden_broj;

      const control_checkups = await global.UF.dataProvider.get_v2(`subjects`, {
         patient_id: this.state.patient_id,
         subject_type: 'control_check_up',
         status: ['re_evaluated', 'final']
      });

      const current_subject = await global.UF.dataProvider.get_v2(`subjects`, {
         id: this.state.id
      });

      const subject = current_subject[0];

      const subject_delovoden_broj = subject.delovoden_broj;

      const number_of_control_checkups = control_checkups.length + 1;

      const broj_na_realizirana_revaluacija = `${subject_delovoden_broj} на ${delovoden_broj}-${number_of_control_checkups}`;

      const subject_to_be_updated_response = await global.UF.dataProvider.get_v2(`subjects`, { id: subject_id });

      const subject_to_be_updated = subject_to_be_updated_response[0];

      subject_to_be_updated.broj_na_realizirana_revaluacija = broj_na_realizirana_revaluacija;

      subject_to_be_updated.Save(() => {});
   };

   handlePublishSubject = async () => {
      const validate_fields = [
         'social_worker_id',
         'psychologist_id',
         'special_educator_and_rehabilitator_id',
         'doctor_id'
      ];
      let valid = true;

      const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
         id: this.state.id
      });

      const subject = subjects[0];

      validate_fields.forEach((field) => {
         valid = valid && subject[field];
      });

      const subject_details = await global.UF.dataProvider.get_v2(`subject_details`, { subject_id: this.state.id });

      if (!subject_details.length) {
         global.UF.setAlertVisibility(
            true,
            'Документот не смее да биде целосно празен, сочувајте ги вашите податоци!',
            'warning'
         );
         return;
      }

      if (!valid) {
         global.UF.setAlertVisibility(
            true,
            'Сите стручни лица мора да потпишат, сочувајте ги вашите податоци!',
            'warning'
         );
         return;
      }

      await this.handleSavingSubject(false);

      if (this.state.subject_type === 'appeal') {
         const temp_subjects = await global.UF.dataProvider.get_v2(`subjects`, {
            patient_id: this.state.patient_id,
            subject_type: ['first_check_up', 'control_check_up'],
            status: 'appealed'
         });

         const last_created_subject = temp_subjects.reduce((next, current) => {
            const next_created_at = new Date(next.created_at);
            const current_created_at = new Date(current.created_at);

            return next_created_at > current_created_at ? next : current;
         }, temp_subjects[0]);

         if (!last_created_subject) {
            global.UF.setAlertVisibility(true, 'Не постоји обжален предмет за овој пациент!', 'warning');
            return;
         }

         last_created_subject.status = 're_evaluated';
         last_created_subject.Save(() => {});
      }

      if (this.state.subject_type === 'archived') {
         if (this.state.subject_details['1_2_number_and_date_of_subject']) {
            subject.subject_type = 'control_check_up';
         } else {
            subject.subject_type = 'first_check_up';
         }
      }

      subject.status = this.state.subject_type === 'archived' ? 'final' : 'issued';
      subject.date_of_publishing = new Date();
      await this.handlePrintingToPdf();
      await this.handleUpdatePreviousSubjectNumberRevaluation();

      subject.Save(async () => {
         const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
            id: this.state.id
         });

         const subject = subjects[0];

         this.setState({ subject, not_editable: true });
      });
   };

   handlePrintingToPdf = async () => {
      try {
         await global.UF.makeRequest_v2(`POST`, `/api/subjects/print`, {
            subject_id: this.state.id
         });
      } catch (err) {
         console.log(err);
         global.UF.handleError(err);
      }
   };

   handlePrintingDocument = async () => {
      global.UF.setMaskVisibility(true);

      await this.handleSavingSubject(false);
      await this.handlePrintingToPdf();

      const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
         id: this.state.id
      });

      const subject = subjects[0];

      setTimeout(() => {
         this.setState({ subject: subject, is_printed: true }, () => {
            global.UF.setMaskVisibility(false);
         });
      }, 1500);
   };

   handleDeleteSubject = async () => {
      if (this.state.subject.status !== 'in_progress') {
         global.UF.setAlertVisibility(
            true,
            `Преметот е со статус "${
               subjectStatuses[this.state.subject.status]
            }". Само предмети со статус "Во изработка" можат да бидат избришани.`,
            'warning'
         );
         return;
      }

      const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
         patient_id: this.state.subject.patient_id,
         subject_type: ['first_check_up', 'control_check_up'],
         status: 'appealed',
         order_by: 'created_at',
         order: 'DESC'
      });

      if (!subjects.length) {
         this.state.subject.Delete(() => {
            this.props.router.navigate(`/patients/${this.state.patient_id}`);
         });
         return;
      }

      const appealedSubject = subjects[0];

      appealedSubject.status = 'issued';

      appealedSubject.Save(() => {
         this.state.subject.Delete(() => {
            this.props.router.navigate(`/patients/${this.state.patient_id}`);
         });
      });
   };

   renderDeleteModal = () => {
      return (
         <DeleteItemModal
            open={this.state.deleteItem !== null}
            onClose={() => this.setState({ deleteItem: null })}
            onDelete={this.handleDeleteSubject}
         />
      );
   };

   renderButtons = () => {
      if (
         (this.state.subject.status == 'in_progress' || this.state.subject.status == 'approved') &&
         this.state.has_kt
      ) {
         return (
            <>
               <Nx_Button
                  label="Избриши предмет"
                  variant="text"
                  size={'small'}
                  onClick={() => this.setState({ deleteItem: this.state.subject })}
               />
               <Nx_Button
                  label="Испринтај документ"
                  variant="text"
                  size={'small'}
                  onClick={this.handlePrintingDocument}
               />
               <Nx_Button label="Издади документ" variant="text" size={'small'} onClick={this.handlePublishSubject} />
               <Nx_Button label="Сочувај" variant="text" size={'small'} onClick={this.handleSavingSubject} />
            </>
         );
      }
      return null;
   };

   renderIFrame = () => {
      const file_path = `${window.location.origin}/api/files/subjects/${
         this.state.subject.file_path
      }?qs=${new Date().getTime()}`;
      return (
         <>
            <div style={{ width: '100%', height: '100%' }}>
               {this.state.is_printed ? (
                  <Nx_Button
                     label="Назад"
                     variant="text"
                     size={'small'}
                     onClick={() => this.setState({ is_printed: false })}
                  />
               ) : null}
               <iframe title="PDF Viewer" key={new Date().getTime()} width="100%" height="100%" src={file_path}>
                  Your browser does not support PDFs. Please download the PDF to view it.
               </iframe>
            </div>
         </>
      );
   };

   render() {
      if (this.state.initializing) {
         return null;
      }
      if (this.state.not_editable || this.state.is_printed) {
         return this.renderIFrame();
      }
      return (
         <Grid style={{ gridTemplateRows: '50px auto', overflow: 'hidden' }}>
            {this.state.deleteItem && this.renderDeleteModal()}
            <Grid className="subject_buttons">{this.renderButtons()}</Grid>

            <div className={`appeal_wrapper ${this.state.subject_type === 'archived' ? 'archived' : ''}`}>
               <div class="c63 doc-content">
                  <div>
                     <p class="c2" style={{ textAlign: 'center', paddingBottom: '5px' }}>
                        <span class="c19 c81">
                           Ј.З.У ЗДРАВСТВЕН ДОМ НА СКОПЈЕ Скопје- О.Е. СЛУЖБА ЗА ФУНКЦИОНАЛНА ПРОЦЕНКА НА ДЕЦА И МЛАДИ
                        </span>
                     </p>
                     <p class="c2" style={{ textAlign: 'center' }}>
                        <span class="c19 c88">
                           СТРУЧЕН ТИМ ЗА КООРДИНАЦИЈА И СЛЕДЕЊЕ НА СПРОВЕДУВАЊЕТО НА ФУНКЦИОНАЛНАТА ПРОЦЕНКА
                        </span>
                     </p>
                     <p class="c2 c28">
                        <span class="c29"></span>
                     </p>
                  </div>
                  <p class="c2 c28">
                     <span class="c50 c19"></span>
                  </p>
                  <p class="c2 c28">
                     <span class="c19 c50"></span>
                  </p>
                  <p class="c2" style={{ textAlign: 'center' }}>
                     <span class="c50 c19 c68">
                        МИСЛЕЊЕ НА СТРУЧНИОТ ТИМ ЗА КООРДИНАЦИЈА И СЛЕДЕЊЕ НА СПРОВЕДУВАЊЕТО НА ФУНКЦИОНАЛНА ПРОЦЕНКА
                     </span>
                  </p>
                  <br />
                  <p class="c2">
                     <span class="c46">Адреса: </span>
                     <span class="c19" id="regions.address">
                        {this.state.regions.address}
                     </span>
                     <span class="c46">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </p>
                  <br />
                  <p class="c2">
                     <span class="c46">Емаил: </span>
                     <span class="c19" id="regions.email">
                        {this.state.regions.email}
                     </span>
                  </p>
                  <br />
                  <p class="c2">
                     <span class="c46">Тел: </span>
                     <span class="c19" id="regions.phone">
                        {this.state.regions.phone}
                     </span>
                  </p>
                  <br />
                  <p class="c2 c109">
                     <span class="c19 c47">МКФ</span>
                  </p>
                  <p class="c17">
                     <span class="c8">ОСНОВНИ И ЛИЧНИ ПОДАТОЦИ</span>
                  </p>
                  <a id="t.88e321d3d918d7e5bfb65f0ea1d1a84fec1c9af6"></a>
                  <a id="t.0"></a>
                  <table class="c65">
                     <tbody>
                        <tr class="c73">
                           <td class="c49 c83" colspan="2" rowspan="1">
                              <p class="c24">
                                 <span class="c8">Информации релевантни за управување со проценката</span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c21">
                           <td class="c9" colspan="2" rowspan="1">
                              <p class="c17" id="h.gjdgxs">
                                 <span class="c12">Број на идентификација: &nbsp;</span>
                                 <span class="c19" id="subject.delovoden_broj">
                                    {this.state.subject.delovoden_broj}
                                 </span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c21">
                           <td class="c83 c84" colspan="2" rowspan="1">
                              <p class="c17" style={{ alignItems: 'flex-start' }}>
                                 <span class="c12">
                                    Стручен тим кој го издал наодот и мислењето на кое се врши евалуација:
                                 </span>
                                 <span class="c19" id="regions.name">
                                    {this.state.region_subject?.name}
                                 </span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c34">
                           <td class="c83 c49" colspan="2" rowspan="1">
                              <p class="c42">
                                 <span class="c8">Основни информации за детето/младинецот</span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c86">
                           <td class="c83 c84" colspan="2" rowspan="1">
                              <p class="c17">
                                 <span class="c12">Име, татково име и презиме: </span>
                                 <span class="c19" id="patients.first_name">
                                    {this.state.patients.first_name}
                                 </span>
                                 <span>&nbsp;</span>
                                 <span class="c19" id="patients.fathers_name">
                                    {this.state.patients.fathers_name}
                                 </span>
                                 <span>&nbsp;</span>
                                 <span class="c19" id="patients.last_name">
                                    {this.state.patients.last_name}
                                 </span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c21">
                           <td class="c9" colspan="1" rowspan="1">
                              <p class="c17">
                                 <span class="c12">Дата и место на раѓање: &nbsp; &nbsp;</span>
                                 <span>&nbsp;</span>
                                 <span class="c19" id="patients.date_of_birth">
                                    {this.state.patients.date_of_birth
                                       ? moment(this.state.patients.date_of_birth).format('DD-MM-YYYY')
                                       : this.state.patients.date_of_birth}
                                 </span>
                              </p>
                           </td>
                           <td class="c14" colspan="1" rowspan="1">
                              <p class="c17">
                                 <span class="c12">ЕМБГ:</span>
                                 <span class="c19" id="patients.id_number">
                                    {this.state.patients.id_number}
                                 </span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c21">
                           <td class="c9" colspan="1" rowspan="1">
                              <p class="c17">
                                 <span class="c12">Адреса и место на живеење/Општина:</span>
                                 <span class="c19" id="patients.address">
                                    {this.state.patients.address}
                                 </span>
                                 <span>&nbsp;/ </span>
                                 <span class="c19" id="patients.community">
                                    {this.state.patients.community}
                                 </span>
                              </p>
                           </td>
                           <td class="c14" colspan="1" rowspan="1">
                              <p class="c70">
                                 <span class="c12">Број на телефон: &nbsp;</span>
                                 <span class="c19" id="patients.contact_phone">
                                    {this.state.patients.contact_phone}
                                 </span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c21">
                           <td class="c9" colspan="1" rowspan="1">
                              <p class="c17">
                                 <span class="c12">Пол: </span>
                                 <span class="c19" id="patients.gender">
                                    {this.state.patients.gender}
                                 </span>
                              </p>
                           </td>
                           <td class="c14" colspan="1" rowspan="1">
                              <span class="c12">Мајчин јазик:</span>
                              <span class="c19" id="1_2_majcin_jezik">
                                 <SubjectInput
                                    type="text"
                                    className="sbj_stb sbj_key_1_2_majcin_jezik"
                                    subject_details={this.state.subject_details}
                                    field={'1_2_majcin_jezik'}
                                    read_only={this.state.read_only}
                                 />
                              </span>
                           </td>
                        </tr>
                        <tr class="c21">
                           <td class="c9" colspan="1" rowspan="1">
                              <p class="c17">
                                 <span class="c12">Место на проценка:</span>
                                 <span class="c19" id="regions.description">
                                    Скопје
                                 </span>
                                 <span>&nbsp;</span>
                              </p>
                           </td>
                           <td class="c14" colspan="1" rowspan="1">
                              <p class="c70">
                                 <span class="c12">Датум:</span>
                                 <span id="1_2_number_and_date">
                                    <SubjectInput
                                       type="date"
                                       className="sbj_date sbj_key_1_2_number_and_date"
                                       subject_details={this.state.subject_details}
                                       field={'1_2_number_and_date'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c17 c28">
                     <span class="c1"></span>
                  </p>
                  <p class="c28 c55">
                     <span class="c8"></span>
                  </p>
                  <p class="c55 c28">
                     <span class="c8"></span>
                  </p>
                  <p class="c55 c28">
                     <span class="c19 c96"></span>
                  </p>
                  <ol class="c5 lst-kix_list_5-0 start" start="1">
                     <li class="c2 c39 li-bullet-0">
                        <span class="c8">ЗДРАВСТВЕНАТА СОСТОЈБА</span>
                     </li>
                  </ol>
                  <p class="c2 c28">
                     <span class="c8"></span>
                  </p>
                  <a id="t.4f22260f1bcb790c578b19e9dd22dc78800f6140"></a>
                  <a id="t.1"></a>
                  <table class="c33">
                     <tbody>
                        <tr class="c43">
                           <td class="c44" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c1">Дијагноза </span>
                              </p>
                              <p class="c2">
                                 <span class="c1">(по МКБ-10)</span>
                              </p>
                           </td>
                           <td class="c26" colspan="1" rowspan="1" id="1_2_dijagnoza">
                              <SubjectTag
                                 reference={'remote.view_code_setup_diagnoses_according_to_icd10'}
                                 className="sbj_tag sbj_key_1_2_dijagnoza"
                                 subject_details={this.state.subject_details}
                                 field={'1_2_dijagnoza'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c43">
                           <td class="c44" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c1">Сегашни третмани</span>
                              </p>
                           </td>
                           <td class="c26" colspan="1" rowspan="1" id="1_2_segasni_tretmani">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_1_2_segasni_tretmani"
                                 subject_details={this.state.subject_details}
                                 field={'1_2_segasni_tretmani'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c2 c28">
                     <span class="c1"></span>
                  </p>
                  <ol class="c5 lst-kix_list_5-0" start="2">
                     <li class="c2 c39 li-bullet-0">
                        <span class="c8">ЛИЧНИ АСПЕКТИ (АНАМНЕЗА ОПИСНО )</span>
                     </li>
                  </ol>
                  <p class="c2 c28">
                     <span class="c8"></span>
                  </p>
                  <a id="t.d02bb50f09ae791abd9de0e6b2cdf9a630c963b2"></a>
                  <a id="t.2"></a>
                  <table class="c33">
                     <tbody>
                        <tr class="c104">
                           <td class="c22" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c1">анамнестички податоци</span>
                              </p>
                              <p class="c2">
                                 <span class="c1">
                                    (се однесува на податоци кои не биле земени во предвид при проценката)
                                 </span>
                              </p>
                           </td>
                           <td class="c13" colspan="1" rowspan="1" id="1_2_anamnesticki_podatoci">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_1_2_anamnesticki_podatoci"
                                 subject_details={this.state.subject_details}
                                 field={'1_2_anamnesticki_podatoci'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c15">
                     <span class="c8"></span>
                  </p>
                  <ol class="c5 lst-kix_list_5-0" start="3">
                     <li class="c39 c92 li-bullet-0">
                        <span class="c8">ЖИВОТНА СРЕДИНА &nbsp;</span>
                     </li>
                  </ol>
                  <p class="c15">
                     <span class="c8"></span>
                  </p>
                  <a id="t.4e17375947c7f81f441773233d3594ec955a563a"></a>
                  <a id="t.3"></a>
                  <table class="c33">
                     <tbody>
                        <tr class="c103">
                           <td class="c85" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c12">
                                    Податоците во делот на животна средина се соодветно изразени во наодот и мислењето:
                                    &nbsp;
                                 </span>
                                 <span class="c8" id="1_3_da_zivotna_sredina">
                                    <SubjectSelect
                                       reference={'local.yesNo'}
                                       className="sbj_ss sbj_key_1_3_da_zivotna_sredina"
                                       subject_details={this.state.subject_details}
                                       field={'1_3_da_zivotna_sredina'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2 p_new_line">
                                 <span class="c12">
                                    Опис на улогата на животната средина која не била земена во предвид при изработка на
                                    наодот и мислењењто:
                                 </span>
                                 <span class="c8" id="1_3_opis_zivotna_sredina" style={{ width: '100%' }}>
                                    <SubjectInput
                                       multiline={true}
                                       type="text"
                                       className="sbj_mtb sbj_key_1_3_opis_zivotna_sredina"
                                       subject_details={this.state.subject_details}
                                       field={'1_3_opis_zivotna_sredina'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2 c28">
                                 <span class="c1"></span>
                              </p>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c17 c28">
                     <span class="c8"></span>
                  </p>
                  <p class="c17 c28">
                     <span class="c8"></span>
                  </p>
                  <ol class="c5 lst-kix_list_5-0" start="4">
                     <li class="c17 c39 li-bullet-0">
                        <span class="c8">ТЕЛЕСНИ СТРУКТУРИ</span>
                     </li>
                  </ol>
                  <a id="t.941a7ecac1f4cd56191493d6baf800083fbc727a"></a>
                  <a id="t.4"></a>
                  <table class="c33">
                     <tbody>
                        <tr class="c57">
                           <td class="c97" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c12">
                                    Податоците во делот на телесни структури се соодветно изразени во наодот и
                                    мислењето: &nbsp;
                                 </span>
                                 <span class="c8" id="1_4_da_telesni_strukturi">
                                    <SubjectSelect
                                       reference={'local.yesNo'}
                                       className="sbj_ss sbj_key_1_4_da_telesni_strukturi"
                                       subject_details={this.state.subject_details}
                                       field={'1_4_da_telesni_strukturi'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2 p_new_line">
                                 <span class="c12">
                                    Опис на оштетувањето на телесните структури кои не биле земени во предвид при
                                    изработка на наодот и мислењењто:{' '}
                                 </span>
                                 <span class="c8" id="1_4_opis_telesni_strukturi" style={{ width: '100%' }}>
                                    <SubjectInput
                                       multiline={true}
                                       type="text"
                                       className="sbj_mtb sbj_key_1_4_opis_telesni_strukturi"
                                       subject_details={this.state.subject_details}
                                       field={'1_4_opis_telesni_strukturi'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c17 c28">
                     <span class="c8"></span>
                  </p>
                  <ol class="c5 lst-kix_list_5-0" start="5">
                     <li class="c17 c39 li-bullet-0">
                        <span class="c8">ТЕЛЕСНИ ФУНКЦИИ</span>
                     </li>
                  </ol>
                  <a id="t.db8c9d8b79863b8399b7b843b1cff84fc5e24e2e"></a>
                  <a id="t.5"></a>
                  <table class="c33">
                     <tbody>
                        <tr class="c16">
                           <td class="c18" colspan="1" rowspan="2">
                              <p class="c24 c28">
                                 <span class="c1"></span>
                              </p>
                              <p class="c24">
                                 <span class="c1">
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp;ОПСЕРВАЦИЈА
                                 </span>
                              </p>
                           </td>
                           <td class="c49 c72" colspan="2" rowspan="1">
                              <p class="c24">
                                 <span class="c1">проценка</span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c75">
                           <td class="c51 c49" colspan="1" rowspan="1">
                              <p class="c24">
                                 <span class="c1">мкф код</span>
                              </p>
                           </td>
                           <td class="c51 c49" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c29">Квалифи-катор</span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c102">
                           <td class="c68 c107" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c12">
                                    Податоците во делот на телесни функции се соодветно изразени во наодот и мислењето:{' '}
                                 </span>
                                 <span class="c8" id="1_5_da_telesni_funkcii">
                                    <SubjectSelect
                                       reference={'local.yesNo'}
                                       className="sbj_ss sbj_key_1_5_da_telesni_funkcii"
                                       subject_details={this.state.subject_details}
                                       field={'1_5_da_telesni_funkcii'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2 p_new_line">
                                 <span class="c12">
                                    Опис на оштетувањето на телесните функции кои не биле соодветно изразени при
                                    изработка на наодот и мислењењто:{' '}
                                 </span>
                                 <span class="c8" id="1_5_opis_telesni_funkcii" style={{ width: '100%' }}>
                                    <SubjectInput
                                       multiline={true}
                                       type="text"
                                       className="sbj_mtb sbj_key_1_5_opis_telesni_funkcii"
                                       subject_details={this.state.subject_details}
                                       field={'1_5_opis_telesni_funkcii'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                           </td>
                           <td class="c51" colspan="1" rowspan="1">
                              <p class="c2" id="5_telesni_funkcii_mkfcode_1">
                                 <SubjectTag
                                    reference={'remote.view_code_setup_codesbody_functions_according_to_icf'}
                                    className="sbj_tag sbj_key_5_telesni_funkcii_mkfcode_1"
                                    subject_details={this.state.subject_details}
                                    field={'5_telesni_funkcii_mkfcode_1'}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                              <p class="c2" id="5_telesni_funkcii_mkfcode_2">
                                 <SubjectTag
                                    reference={'remote.view_code_setup_codesbody_functions_according_to_icf'}
                                    className="sbj_tag sbj_key_5_telesni_funkcii_mkfcode_2"
                                    subject_details={this.state.subject_details}
                                    field={'5_telesni_funkcii_mkfcode_2'}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                              <p class="c2" id="5_telesni_funkcii_mkfcode_3">
                                 <SubjectTag
                                    reference={'remote.view_code_setup_codesbody_functions_according_to_icf'}
                                    className="sbj_tag sbj_key_5_telesni_funkcii_mkfcode_3"
                                    subject_details={this.state.subject_details}
                                    field={'5_telesni_funkcii_mkfcode_3'}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                              <p class="c2" id="5_telesni_funkcii_mkfcode_4">
                                 <SubjectTag
                                    reference={'remote.view_code_setup_codesbody_functions_according_to_icf'}
                                    className="sbj_tag sbj_key_5_telesni_funkcii_mkfcode_4"
                                    subject_details={this.state.subject_details}
                                    field={'5_telesni_funkcii_mkfcode_4'}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                              <p class="c2" id="5_telesni_funkcii_mkfcode_5">
                                 <SubjectTag
                                    reference={'remote.view_code_setup_codesbody_functions_according_to_icf'}
                                    className="sbj_tag sbj_key_5_telesni_funkcii_mkfcode_5"
                                    subject_details={this.state.subject_details}
                                    field={'5_telesni_funkcii_mkfcode_5'}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                           </td>
                           <td class="c51" colspan="1" rowspan="1">
                              <p class="c2" id="5_telesni_funkcii_kvalifikator_1">
                                 <SubjectSelect
                                    reference={'remote.view_code_setup_icf_qualifier'}
                                    className="sbj_ms sbj_key_5_telesni_funkcii_kvalifikator_1"
                                    subject_details={this.state.subject_details}
                                    field={'5_telesni_funkcii_kvalifikator_1'}
                                    multiple={true}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                              <p class="c2" id="5_telesni_funkcii_kvalifikator_2">
                                 <SubjectSelect
                                    reference={'remote.view_code_setup_icf_qualifier'}
                                    className="sbj_ms sbj_key_5_telesni_funkcii_kvalifikator_2"
                                    subject_details={this.state.subject_details}
                                    field={'5_telesni_funkcii_kvalifikator_2'}
                                    multiple={true}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                              <p class="c2" id="5_telesni_funkcii_kvalifikator_3">
                                 <SubjectSelect
                                    reference={'remote.view_code_setup_icf_qualifier'}
                                    className="sbj_ms sbj_key_5_telesni_funkcii_kvalifikator_3"
                                    subject_details={this.state.subject_details}
                                    field={'5_telesni_funkcii_kvalifikator_3'}
                                    multiple={true}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                              <p class="c2" id="5_teelesni_funkcii_kvalifikator_4">
                                 <SubjectSelect
                                    reference={'remote.view_code_setup_icf_qualifier'}
                                    className="sbj_ms sbj_key_5_teelesni_funkcii_kvalifikator_4"
                                    subject_details={this.state.subject_details}
                                    field={'5_teelesni_funkcii_kvalifikator_4'}
                                    multiple={true}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                              <p class="c2" id="5_telesni_funkcii_kvalifikator_5">
                                 <SubjectSelect
                                    reference={'remote.view_code_setup_icf_qualifier'}
                                    className="sbj_ms sbj_key_5_telesni_funkcii_kvalifikator_5"
                                    subject_details={this.state.subject_details}
                                    field={'5_telesni_funkcii_kvalifikator_5'}
                                    multiple={true}
                                    read_only={this.state.read_only}
                                 />
                              </p>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c17 c28">
                     <span class="c8"></span>
                  </p>
                  <ol class="c5 lst-kix_list_5-0" start="6">
                     <li class="c17 c39 li-bullet-0">
                        <span class="c8">УЧЕСТВО</span>
                     </li>
                  </ol>
                  <a id="t.dac3ba78ba1a051cc17d97bfc8326d08fe0da4a6"></a>
                  <a id="t.6"></a>
                  <table class="c87">
                     <tbody>
                        <tr class="c80">
                           <td class="c59" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c12">
                                    Податоците во делот на учество се соодветно изразени во наодот и мислењето:{' '}
                                 </span>
                                 <span class="c8" id="1_6_da_ucestvo">
                                    <SubjectSelect
                                       reference={'local.yesNo'}
                                       className="sbj_ss sbj_key_1_6_da_ucestvo"
                                       subject_details={this.state.subject_details}
                                       field={'1_6_da_ucestvo'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2 p_new_line">
                                 <span class="c12">
                                    Опис на учеството на детете или младинецот кој не бил земен во предвид при изработка
                                    на наодот и мислењењто:{' '}
                                 </span>
                                 <span class="c8" id="1_6_opis_ucestvo" style={{ width: '100%' }}>
                                    <SubjectInput
                                       multiline={true}
                                       type="text"
                                       className="sbj_mtb sbj_key_1_6_opis_ucestvo"
                                       subject_details={this.state.subject_details}
                                       field={'1_6_opis_ucestvo'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2 c28">
                                 <span class="c8"></span>
                              </p>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c17 c28">
                     <span class="c8"></span>
                  </p>
                  <a id="t.643507c6b257de45a1d26fa9389b8eb97109985a"></a>
                  <a id="t.7"></a>
                  <table class="c33">
                     <tbody>
                        <tr class="c48">
                           <td class="c37" colspan="1" rowspan="2">
                              <p class="c2 c28">
                                 <span class="c1"></span>
                              </p>
                           </td>
                           <td class="c25 c49" colspan="1" rowspan="2">
                              <p class="c24 c28">
                                 <span class="c1"></span>
                              </p>
                              <p class="c24">
                                 <span class="c1">ОПСЕРВАЦИЈА</span>
                              </p>
                           </td>
                           <td class="c49 c60" colspan="3" rowspan="1">
                              <p class="c24 c62">
                                 <span class="c1">проценка</span>
                              </p>
                           </td>
                           <td class="c49 c61" colspan="3" rowspan="1">
                              <p class="c24">
                                 <span class="c1">улога на околината</span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c74">
                           <td class="c27 c49" colspan="1" rowspan="1">
                              <p class="c24">
                                 <span class="c1">мкф код</span>
                              </p>
                           </td>
                           <td class="c27 c49" colspan="1" rowspan="1">
                              <p class="c24">
                                 <span class="c29">Квалифи-катор</span>
                              </p>
                           </td>
                           <td class="c0" colspan="1" rowspan="1">
                              <p class="c24">
                                 <span class="c1">индикатор </span>
                              </p>
                           </td>
                           <td class="c20 c49" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c1">олеснувач</span>
                              </p>
                           </td>
                           <td class="c41 c49" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c1">бариера</span>
                              </p>
                           </td>
                           <td class="c54 c49" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c1">спецификација</span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c6">
                           <td class="c37" colspan="1" rowspan="2">
                              <p class="c2">
                                 <span class="c1">d1 </span>
                              </p>
                              <p class="c2">
                                 <span class="c1">учење</span>
                              </p>
                           </td>
                           <td class="c25" colspan="1" rowspan="1" id="6_1_1_ucenje">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_1_1_ucenje"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_1_ucenje'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_1_1_ucenje_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_1_1_ucenje_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_1_ucenje_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_1_1_ucenje_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_1_1_ucenje_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_1_ucenje_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_1_ucenje_indikator_1">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_1_ucenje_indikator_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_ucenje_indikator_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_1_ucenje_olesnu_vac_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_1_ucenje_olesnu_vac_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_ucenje_olesnu_vac_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_1_ucenje_bariera_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_1_ucenje_bariera_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_ucenje_bariera_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_1_ucenje_specifikacija_1">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_1_ucenje_specifikacija_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_ucenje_specifikacija_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c6">
                           <td class="c25" colspan="1" rowspan="1" id="6_1_2_ucenje">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_1_2_ucenje"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_2_ucenje'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_1_2_ucenje_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_1_2_ucenje_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_2_ucenje_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_1_2_ucenje_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_1_2_ucenje_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_2_ucenje_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_1_ucenje_indikator_2">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_1_ucenje_indikator_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_ucenje_indikator_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_1_ucenje_olesnu_vac_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_1_ucenje_olesnu_vac_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_ucenje_olesnu_vac_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_1_ucenje_bariera_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_1_ucenje_bariera_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_ucenje_bariera_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_1_ucenje_specifikacija_2">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_1_ucenje_specifikacija_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_1_ucenje_specifikacija_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c100">
                           <td class="c37" colspan="1" rowspan="2">
                              <p class="c2">
                                 <span class="c1">d2 </span>
                              </p>
                              <p class="c2">
                                 <span class="c1">општи задачи</span>
                              </p>
                           </td>
                           <td class="c25" colspan="1" rowspan="1" id="6_2_1_opsti_zadaci">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_2_1_opsti_zadaci"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_1_opsti_zadaci'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_2_1_opsti_zadaci_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_2_1_opsti_zadaci_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_1_opsti_zadaci_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_2_1_opsti_zadaci_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_2_1_opsti_zadaci_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_1_opsti_zadaci_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_2_opsti_zadaci_indikator_1">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_2_opsti_zadaci_indikator_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_opsti_zadaci_indikator_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_2_opsti_zadaci_olesnu_vac_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_2_opsti_zadaci_olesnu_vac_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_opsti_zadaci_olesnu_vac_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_2_opsti_zadaci_bariera_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_2_opsti_zadaci_bariera_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_opsti_zadaci_bariera_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_2_opsti_zadaci_specifikacija_1">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_2_opsti_zadaci_specifikacija_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_opsti_zadaci_specifikacija_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c16">
                           <td class="c25" colspan="1" rowspan="1" id="6_2_2_opsti_zadaci">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_2_2_opsti_zadaci"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_2_opsti_zadaci'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_2_2_opsti_zadaci_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_2_2_opsti_zadaci_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_2_opsti_zadaci_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_2_2_opsti_zadaci_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_2_2_opsti_zadaci_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_2_opsti_zadaci_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_2_opsti_zadaci_indikator_2">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_2_opsti_zadaci_indikator_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_opsti_zadaci_indikator_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_2_opsti_zadaci_olesnu_vac_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_2_opsti_zadaci_olesnu_vac_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_opsti_zadaci_olesnu_vac_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_2_opsti_zadaci_bariera_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_2_opsti_zadaci_bariera_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_opsti_zadaci_bariera_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_2_opsti_zadaci_specifikacija_2">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_2_opsti_zadaci_specifikacija_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_2_opsti_zadaci_specifikacija_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c10">
                           <td class="c37" colspan="1" rowspan="3">
                              <p class="c2">
                                 <span class="c1">d3 комуни-кација</span>
                              </p>
                           </td>
                           <td class="c25" colspan="1" rowspan="1" id="6_3_1_komunikacija">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_3_1_komunikacija"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_1_komunikacija'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_3_1_komunikacija_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_3_1_komunikacija_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_1_komunikacija_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_3_1_komunikacija_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_3_1_komunikacija_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_1_komunikacija_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_3_komunikacija_indikator_1">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_3_komunikacija_indikator_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_indikator_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_3_komunikacija_olesnu_vac_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_3_komunikacija_olesnu_vac_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_olesnu_vac_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_3_komunikacija_bariera_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_3_komunikacija_bariera_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_bariera_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_3_komunikacija_specifikacija_1">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_3_komunikacija_specifikacija_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_specifikacija_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c90">
                           <td class="c25" colspan="1" rowspan="1" id="6_3_2_komunikacija">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_3_2_komunikacija"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_2_komunikacija'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_3_2_komunikacija_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_3_2_komunikacija_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_2_komunikacija_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_3_2_komunikacija_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_3_2_komunikacija_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_2_komunikacija_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_3_komunikacija_indikator_2">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_3_komunikacija_indikator_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_indikator_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_3_komunikacija_olesnu_vac_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_3_komunikacija_olesnu_vac_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_olesnu_vac_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_3_komunikacija_bariera_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_3_komunikacija_bariera_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_bariera_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_3_komunikacija_specifikacija_2">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_3_komunikacija_specifikacija_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_specifikacija_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c16">
                           <td class="c25" colspan="1" rowspan="1" id="6_3_3_komunikacija">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_3_3_komunikacija"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_3_komunikacija'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_3_3_komunikacija_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_3_3_komunikacija_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_3_komunikacija_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_3_3_komunikacija_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_3_3_komunikacija_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_3_komunikacija_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_3_komunikacija_indikator_3">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_3_komunikacija_indikator_3"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_indikator_3'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_3_komunikacija_olesnu_vac_3">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_3_komunikacija_olesnu_vac_3"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_olesnu_vac_3'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_3_komunikacija_bariera_3">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_3_komunikacija_bariera_3"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_bariera_3'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_3_komunikacija_specifikacija_3">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_3_komunikacija_specifikacija_3"
                                 subject_details={this.state.subject_details}
                                 field={'6_3_komunikacija_specifikacija_3'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c31">
                           <td class="c37" colspan="1" rowspan="3">
                              <p class="c2">
                                 <span class="c1">d4 </span>
                              </p>
                              <p class="c2">
                                 <span class="c1">мобил-</span>
                              </p>
                              <p class="c2">
                                 <span class="c1">ност</span>
                              </p>
                           </td>
                           <td class="c25" colspan="1" rowspan="1" id="6_4_1_mobilnost">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_4_1_mobilnost"
                                 subject_details={this.state.subject_details}
                                 read_only={this.state.read_only}
                                 field={'6_4_1_mobilnost'}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_4_1_mobilnost_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_4_1_mobilnost_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_1_mobilnost_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_4_1_mobilnost_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_4_1_mobilnost_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_1_mobilnost_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_4_mobilnost_indikator_1">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_4_mobilnost_indikator_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_indikator_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_4_mobilnost_olesnu_vac_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_4_mobilnost_olesnu_vac_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_olesnu_vac_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_4_mobilnost_bariera_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_4_mobilnost_bariera_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_bariera_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_4_mobilnost_specifikacija_1">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_4_mobilnost_specifikacija_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_specifikacija_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c16">
                           <td class="c25" colspan="1" rowspan="1" id="6_4_2_mobilnost">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_4_2_mobilnost"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_2_mobilnost'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_4_2_mobilnost_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_4_2_mobilnost_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_2_mobilnost_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_4_2_mobilnost_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_4_2_mobilnost_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_2_mobilnost_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_4_mobilnost_indikator_2">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_4_mobilnost_indikator_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_indikator_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_4_mobilnost_olesnu_vac_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_4_mobilnost_olesnu_vac_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_olesnu_vac_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_4_mobilnost_bariera_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_4_mobilnost_bariera_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_bariera_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_4_mobilnost_specifikacija_2">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_4_mobilnost_specifikacija_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_specifikacija_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c16">
                           <td class="c25" colspan="1" rowspan="1" id="6_4_3_mobilnost">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_4_3_mobilnost"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_3_mobilnost'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_4_3_mobilnost_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_4_3_mobilnost_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_3_mobilnost_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_4_3_mobilnost_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_4_3_mobilnost_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_3_mobilnost_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c35" colspan="1" rowspan="1" id="6_4_mobilnost_indikator_3">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_4_mobilnost_indikator_3"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_indikator_3'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c20" colspan="1" rowspan="1" id="6_4_mobilnost_olesnu_vac_3">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_4_mobilnost_olesnu_vac_3"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_olesnu_vac_3'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c41" colspan="1" rowspan="1" id="6_4_mobilnost_bariera_3">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_4_mobilnost_bariera_3"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_bariera_3'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c54" colspan="1" rowspan="1" id="6_4_mobilnost_specifikacija_3">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_4_mobilnost_specifikacija_3"
                                 subject_details={this.state.subject_details}
                                 field={'6_4_mobilnost_specifikacija_3'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c30">
                           <td class="c37" colspan="1" rowspan="2">
                              <p class="c2">
                                 <span class="c1">d5</span>
                              </p>
                              <p class="c2">
                                 <span class="c1">грижа</span>
                              </p>
                              <p class="c2">
                                 <span class="c1">за себе</span>
                              </p>
                              <p class="c2 c28">
                                 <span class="c1"></span>
                              </p>
                           </td>
                           <td class="c25" colspan="1" rowspan="1" id="6_5_1_griza_za_sebe">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_5_1_griza_za_sebe"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_1_griza_za_sebe'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_5_1_griza_za_sebe_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_5_1_griza_za_sebe_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_1_griza_za_sebe_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_5_1_griza_za_sebe_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_5_1_griza_za_sebe_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_1_griza_za_sebe_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_5_griza_za_sebe_indikator_1">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_5_griza_za_sebe_indikator_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_griza_za_sebe_indikator_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_5_griza_za_sebe_olesnu_vac_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_5_griza_za_sebe_olesnu_vac_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_griza_za_sebe_olesnu_vac_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c4" colspan="1" rowspan="1" id="6_5_griza_za_sebe_bariera_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_5_griza_za_sebe_bariera_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_griza_za_sebe_bariera_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c56" colspan="1" rowspan="1" id="6_5_griza_za_sebe_specifikacija_1">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_5_griza_za_sebe_specifikacija_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_griza_za_sebe_specifikacija_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c30">
                           <td class="c25" colspan="1" rowspan="1" id="6_5_2_griza_za_sebe">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_5_2_griza_za_sebe"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_2_griza_za_sebe'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_5_2_griza_za_sebe_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_5_2_griza_za_sebe_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_2_griza_za_sebe_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_5_2_griza_za_sebe_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_5_2_griza_za_sebe_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_2_griza_za_sebe_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_5_griza_za_sebe_indikator_2">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_5_griza_za_sebe_indikator_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_griza_za_sebe_indikator_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_5_griza_za_sebe_olesnu_vac_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_5_griza_za_sebe_olesnu_vac_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_griza_za_sebe_olesnu_vac_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c4" colspan="1" rowspan="1" id="6_5_griza_za_sebe_bariera_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_5_griza_za_sebe_bariera_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_griza_za_sebe_bariera_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c56" colspan="1" rowspan="1" id="6_5_griza_za_sebe_specifikacija_2">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_5_griza_za_sebe_specifikacija_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_5_griza_za_sebe_specifikacija_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c10">
                           <td class="c37" colspan="1" rowspan="2">
                              <p class="c2">
                                 <span class="c1">d6 </span>
                              </p>
                              <p class="c2">
                                 <span class="c1">живот</span>
                              </p>
                              <p class="c2">
                                 <span class="c1">во домот</span>
                              </p>
                           </td>
                           <td class="c25" colspan="1" rowspan="1" id="6_6_1_zivot_vo_domot">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_6_1_zivot_vo_domot"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_1_zivot_vo_domot'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_6_1_zivot_vo_domot_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_6_1_zivot_vo_domot_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_1_zivot_vo_domot_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_6_1_zivot_vo_domot_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_6_1_zivot_vo_domot_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_1_zivot_vo_domot_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_6_zivot_vo_domot_indikator_1">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_6_zivot_vo_domot_indikator_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_zivot_vo_domot_indikator_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_6_zivot_vo_domot_olesnu_vac_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_6_zivot_vo_domot_olesnu_vac_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_zivot_vo_domot_olesnu_vac_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c4" colspan="1" rowspan="1" id="6_6_zivot_vo_domot_bariera_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_6_zivot_vo_domot_bariera_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_zivot_vo_domot_bariera_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c56" colspan="1" rowspan="1" id="6_6_zivot_vo_domot_specifikacija_1">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_6_zivot_vo_domot_specifikacija_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_zivot_vo_domot_specifikacija_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c10">
                           <td class="c25" colspan="1" rowspan="1" id="6_6_2_zivot_vo_domot">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_6_2_zivot_vo_domot"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_2_zivot_vo_domot'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_6_2_zivot_vo_domot_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_6_2_zivot_vo_domot_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_2_zivot_vo_domot_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_6_2_zivot_vo_domot_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_6_2_zivot_vo_domot_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_2_zivot_vo_domot_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_6_zivot_vo_domot_indikator_2">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_6_zivot_vo_domot_indikator_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_zivot_vo_domot_indikator_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_6_zivot_vo_domot_olesnu_vac_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_6_zivot_vo_domot_olesnu_vac_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_zivot_vo_domot_olesnu_vac_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c4" colspan="1" rowspan="1" id="6_6_zivot_vo_domot_bariera_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_6_zivot_vo_domot_bariera_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_zivot_vo_domot_bariera_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c56" colspan="1" rowspan="1" id="6_6_zivot_vo_domot_specifikacija_2">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_6_zivot_vo_domot_specifikacija_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_6_zivot_vo_domot_specifikacija_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c10">
                           <td class="c37" colspan="1" rowspan="2">
                              <p class="c2">
                                 <span class="c1">d7 интеракции</span>
                              </p>
                           </td>
                           <td class="c25" colspan="1" rowspan="1" id="6_7_1_interakcii">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_7_1_interakcii"
                                 subject_details={this.state.subject_details}
                                 read_only={this.state.read_only}
                                 field={'6_7_1_interakcii'}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_7_1_interakcii_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_7_1_interakcii_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_7_1_interakcii_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_7_1_interakcii_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_7_1_interakcii_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_7_1_interakcii_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_7_interakcii_indikator_1">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_7_interakcii_indikator_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_7_interakcii_indikator_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_7_interakcii_olesnu_vac_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_7_interakcii_olesnu_vac_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_7_interakcii_olesnu_vac_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c4" colspan="1" rowspan="1" id="6_7_interakcii_bariera_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_7_interakcii_bariera_1"
                                 subject_details={this.state.subject_details}
                                 read_only={this.state.read_only}
                                 field={'6_7_interakcii_bariera_1'}
                              />
                           </td>

                           <td class="c56" colspan="1" rowspan="1" id="2_6_7_interakcii_specifikacija_1">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_2_6_7_interakcii_specifikacija_1"
                                 subject_details={this.state.subject_details}
                                 field={'2_6_7_interakcii_specifikacija_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c10">
                           <td class="c25" colspan="1" rowspan="1" id="6_7_2_interakcii">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_7_2_interakcii"
                                 subject_details={this.state.subject_details}
                                 field={'6_7_2_interakcii'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_7_2_interakcii_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_7_2_interakcii_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_7_2_interakcii_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="2_6_7_2_interakcii_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_2_6_7_2_interakcii_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'2_6_7_2_interakcii_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_7_interakcii_indikator_2">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_7_interakcii_indikator_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_7_interakcii_indikator_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_7_interakcii_olesnu_vac_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_7_interakcii_olesnu_vac_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_7_interakcii_olesnu_vac_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c4" colspan="1" rowspan="1" id="6_7_interakcii_bariera_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_7_interakcii_bariera_2"
                                 subject_details={this.state.subject_details}
                                 read_only={this.state.read_only}
                                 field={'6_7_interakcii_bariera_2'}
                              />
                           </td>
                           <td class="c56" colspan="1" rowspan="1" id="2_6_7_interakcii_specifikacija_2">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_2_6_7_interakcii_specifikacija_2"
                                 subject_details={this.state.subject_details}
                                 field={'2_6_7_interakcii_specifikacija_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c30">
                           <td class="c37" colspan="1" rowspan="2">
                              <p class="c2">
                                 <span class="c1">d8 </span>
                              </p>
                              <p class="c2">
                                 <span class="c1">главни животни области</span>
                              </p>
                           </td>
                           <td class="c25" colspan="1" rowspan="1" id="6_8_1_glavni_zivotni_oblasti">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_8_1_glavni_zivotni_oblasti"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_1_glavni_zivotni_oblasti'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_8_1_glavni_zivotni_oblasti_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_8_1_glavni_zivotni_oblasti_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_1_glavni_zivotni_oblasti_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_8_1_glavni_zivotni_oblasti_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_8_1_glavni_zivotni_oblasti_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_1_glavni_zivotni_oblasti_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_8_glavni_zivotni_oblasti_indikator_1">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_8_glavni_zivotni_oblasti_indikator_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_glavni_zivotni_oblasti_indikator_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_8_glavni_zivotni_oblasti_olesnu_vac_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_8_glavni_zivotni_oblasti_olesnu_vac_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_glavni_zivotni_oblasti_olesnu_vac_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_8_glavni_zivotni_oblasti_bariera_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_8_glavni_zivotni_oblasti_bariera_1"
                                 subject_details={this.state.subject_details}
                                 read_only={this.state.read_only}
                                 field={'6_8_glavni_zivotni_oblasti_bariera_1'}
                              />
                           </td>
                           <td class="c89" colspan="1" rowspan="1" id="6_8_glavni_zivotni_oblasti_specifikacija_1">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_8_glavni_zivotni_oblasti_specifikacija_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_glavni_zivotni_oblasti_specifikacija_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c30">
                           <td class="c25" colspan="1" rowspan="1" id="6_8_2_glavni_zivotni_oblasti">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_8_2_glavni_zivotni_oblasti"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_2_glavni_zivotni_oblasti'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_8_2_glavni_zivotni_oblasti_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_8_2_glavni_zivotni_oblasti_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_2_glavni_zivotni_oblasti_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_8_2_glavni_zivotni_oblasti_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_8_2_glavni_zivotni_oblasti_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_2_glavni_zivotni_oblasti_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_8_glavni_zivotni_oblasti_indikator_2">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_8_glavni_zivotni_oblasti_indikator_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_glavni_zivotni_oblasti_indikator_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_8_glavni_zivotni_oblasti_olesnu_vac_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_8_glavni_zivotni_oblasti_olesnu_vac_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_glavni_zivotni_oblasti_olesnu_vac_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_8_glavni_zivotni_oblasti_bariera_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_8_glavni_zivotni_oblasti_bariera_2"
                                 subject_details={this.state.subject_details}
                                 read_only={this.state.read_only}
                                 field={'6_8_glavni_zivotni_oblasti_bariera_2'}
                              />
                           </td>
                           <td class="c89" colspan="1" rowspan="1" id="6_8_glavni_zivotni_oblasti_specifikacija_2">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_8_glavni_zivotni_oblasti_specifikacija_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_8_glavni_zivotni_oblasti_specifikacija_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c10">
                           <td class="c37" colspan="1" rowspan="2">
                              <p class="c2">
                                 <span class="c1">d9 социјален живот</span>
                              </p>
                           </td>
                           <td class="c25" colspan="1" rowspan="1" id="6_9_1_socijalen_zivot">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_9_1_socijalen_zivot"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_1_socijalen_zivot'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_9_1_socijalen_zivot_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_9_1_socijalen_zivot_mkfcode"
                                 subject_details={this.state.subject_details}
                                 read_only={this.state.read_only}
                                 field={'6_9_1_socijalen_zivot_mkfcode'}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_9_1_socijalen_zivot_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_9_1_socijalen_zivot_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_1_socijalen_zivot_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_9_socijalen_zivot_indikator_1">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_9_socijalen_zivot_indikator_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_socijalen_zivot_indikator_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_9_socijalen_zivot_olesnu_vac_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_9_socijalen_zivot_olesnu_vac_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_socijalen_zivot_olesnu_vac_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_9_socijalen_zivot_bariera_1">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_9_socijalen_zivot_bariera_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_socijalen_zivot_bariera_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c89" colspan="1" rowspan="1" id="6_9_socijalen_zivot_specifikacija_1">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_9_socijalen_zivot_specifikacija_1"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_socijalen_zivot_specifikacija_1'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                        <tr class="c10">
                           <td class="c25" colspan="1" rowspan="1" id="6_9_2_socijalen_zivot">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_9_2_socijalen_zivot"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_2_socijalen_zivot'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_9_2_socijalen_zivot_mkfcode">
                              <SubjectTag
                                 reference={'remote.view_code_setup_participation_codes'}
                                 className="sbj_tag sbj_key_6_9_2_socijalen_zivot_mkfcode"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_2_socijalen_zivot_mkfcode'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_9_2_socijalen_zivot_kvalifikator">
                              <SubjectSelect
                                 reference={'remote.view_code_setup_icf_qualifier'}
                                 className="sbj_ss sbj_key_6_9_2_socijalen_zivot_kvalifikator"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_2_socijalen_zivot_kvalifikator'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c11" colspan="1" rowspan="1" id="6_9_socijalen_zivot_indikator_2">
                              <SubjectTag
                                 reference={'remote.view_code_setup_indicator'}
                                 className="sbj_tag sbj_key_6_9_socijalen_zivot_indikator_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_socijalen_zivot_indikator_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c40" colspan="1" rowspan="1" id="6_9_socijalen_zivot_olesnu_vac_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_indicator'}
                                 className="sbj_tag sbj_key_6_9_socijalen_zivot_olesnu_vac_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_socijalen_zivot_olesnu_vac_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c27" colspan="1" rowspan="1" id="6_9_socijalen_zivot_bariera_2">
                              <SubjectTag
                                 reference={'remote.view_facilitator_and_barrier_qualifier'}
                                 className="sbj_tag sbj_key_6_9_socijalen_zivot_bariera_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_socijalen_zivot_bariera_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                           <td class="c89" colspan="1" rowspan="1" id="6_9_socijalen_zivot_specifikacija_2">
                              <SubjectInput
                                 multiline={true}
                                 type="text"
                                 className="sbj_mtb sbj_key_6_9_socijalen_zivot_specifikacija_2"
                                 subject_details={this.state.subject_details}
                                 field={'6_9_socijalen_zivot_specifikacija_2'}
                                 read_only={this.state.read_only}
                              />
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c17 c28">
                     <span class="c8"></span>
                  </p>
                  <p class="c98">
                     <span class="c8">МИСЛЕЊЕ (индивидуален семеен план за поддршка)</span>
                  </p>
                  <a id="t.c1c39d9813d1039f63038bdd2496b02a70cbbe61"></a>
                  <a id="t.9"></a>
                  <table class="c87">
                     <tbody>
                        <tr class="c80">
                           <td class="c59" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c12">
                                    Наодот изразен во мислењето е соодветен на функционалната состојба на дететето или
                                    младинецот:{' '}
                                 </span>
                                 <span class="c8" id="7_0_da_mislenje">
                                    <SubjectSelect
                                       reference={'local.yesNo'}
                                       className="sbj_ss sbj_key_7_0_da_mislenje"
                                       subject_details={this.state.subject_details}
                                       field={'7_0_da_mislenje'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2 p_new_line">
                                 <span class="c12">Во Наодот треба да се додадат следниот опис:</span>
                                 <span class="c8" id="7_0_mislenje">
                                    <SubjectInput
                                       multiline={true}
                                       type="text"
                                       className="sbj_mtb sbj_key_7_0_mislenje"
                                       subject_details={this.state.subject_details}
                                       field={'7_0_mislenje'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2">
                                 <span class="c12">
                                    Целите на учество дадени во мислењето се соодветни на функционалната состојба на
                                    дететето или младинецот:
                                 </span>
                                 <span class="c12 c19" id="7_1_da_mislenje">
                                    <SubjectSelect
                                       reference={'local.yesNo'}
                                       className="sbj_ss sbj_key_7_1_da_mislenje"
                                       subject_details={this.state.subject_details}
                                       field={'7_1_da_mislenje'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2 p_new_line">
                                 <span class="c12">
                                    Во мислењењто треба да се променат и/или додадат следните цели:
                                 </span>
                                 <span class="c12 c19" id="7_1_mislenje" style={{ width: '100%' }}>
                                    <SubjectInput
                                       multiline={true}
                                       type="text"
                                       className="sbj_mtb sbj_key_7_1_mislenje"
                                       subject_details={this.state.subject_details}
                                       field={'7_1_mislenje'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2">
                                 <span class="c12">
                                    Препорачаните услуги дадени во мислењето се соодветни на функционалната состојба на
                                    дететето или младинецот:
                                 </span>
                                 <span class="c12 c19" id="7_2_da_mislenje">
                                    <SubjectSelect
                                       reference={'local.yesNo'}
                                       className="sbj_ss sbj_key_7_2_da_mislenje"
                                       subject_details={this.state.subject_details}
                                       field={'7_2_da_mislenje'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                              <p class="c2 p_new_line">
                                 <span class="c12">
                                    Во мислењењто треба да се променат и/или додадат следните препораки:
                                 </span>
                                 <span class="c12 c19" id="7_2_mislenje" style={{ width: '100%' }}>
                                    <SubjectInput
                                       multiline={true}
                                       type="text"
                                       className="sbj_mtb sbj_key_7_2_mislenje"
                                       subject_details={this.state.subject_details}
                                       field={'7_2_mislenje'}
                                       read_only={this.state.read_only}
                                    />
                                 </span>
                              </p>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c98 c28">
                     <span class="c8"></span>
                  </p>
                  <p class="c17">
                     <span class="c8">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;МИСЛЕЊЕ НА СТРУЧНИОТ ТИМ ЗА КООРДИНАЦИЈА И
                        СЛЕДЕЊЕ НА СПРОВЕДУВАЊЕТО НА ФУНКЦИОНАЛНАТА ПРОЦЕНКА
                     </span>
                  </p>
                  <a id="t.df32691fbbca54afe173c8bae14a89f0f81a0f1c"></a>
                  <a id="t.10"></a>
                  <table class="c33">
                     <tbody>
                        <tr class="c95">
                           <td class="c66 c49" colspan="1" rowspan="1">
                              <p class="c2">
                                 <span class="c1">Описно</span>
                              </p>
                           </td>
                        </tr>
                        <tr
                           class="c111"
                           id="7_2_mislenje_opisno"
                           style={{ height: 'auto', margin: '10px 0', display: 'block' }}
                        >
                           <SubjectInput
                              multiline={true}
                              type="text"
                              className="sbj_mtb sbj_key_7_2_mislenje_opisno"
                              subject_details={this.state.subject_details}
                              field={'7_2_mislenje_opisno'}
                              read_only={this.state.read_only}
                           />
                        </tr>
                     </tbody>
                  </table>
                  <ol class="c5 lst-kix_list_21-0 start" start="8">
                     <li class="c17 c39 li-bullet-0">
                        <span class="c8">РАЗМЕНА НА ИНФОРМАЦИИ &nbsp;</span>
                        <span class="c1">( со кого: семеен доктор, училиште, градинка):</span>
                     </li>
                  </ol>
                  <a id="t.daf9b0720b68348a5bf2942c921f58707c771d22"></a>
                  <a id="t.11"></a>
                  <table class="c33" id="7_3_razmena_na_informacii">
                     <SubjectInput
                        multiline={true}
                        type="text"
                        className="sbj_mtb sbj_key_7_3_razmena_na_informacii"
                        subject_details={this.state.subject_details}
                        field={'7_3_razmena_na_informacii'}
                        read_only={this.state.read_only}
                     />
                  </table>
                  <p class="c17 c28">
                     <span class="c8"></span>
                  </p>
                  <a id="t.f1f8ad72233182811085a836d56a9e7c5fbfb9ae"></a>
                  <a id="t.12"></a>
                  <table class="c36">
                     <tbody>
                        <tr class="c80">
                           <td class="c94" colspan="2" rowspan="1">
                              <p class="c17">
                                 <span class="c1">Вклучени експерти:</span>
                              </p>
                           </td>
                           <td class="c93" colspan="2" rowspan="1">
                              <p class="c42">
                                 <span class="c1">Потпис на експерти</span>
                              </p>
                           </td>
                        </tr>
                        <tr class="c76">
                           <td class="c38" colspan="2" rowspan="1">
                              {this.state.social_worker.first_name} {this.state.social_worker.last_name} -{' '}
                              {global.UF.tr(this.state.social_worker.specialist_type)}
                           </td>
                           <td class="c32" colspan="1" rowspan="1" id="approved_social_worker">
                              <SubjectApproveButton
                                 className="sbj_btn sbj_key_approved_social_worker"
                                 user={this.props.user}
                                 subject_id={this.state.id}
                                 field={'approved_social_worker'}
                                 updateSubject={this.fetchSpecialistAndDoctors}
                                 subject_type={'appeal'}
                                 patient={this.state.patients}
                                 subject={this.state.subject}
                              />
                           </td>
                        </tr>
                        <tr class="c76">
                           <td class="c38" colspan="2" rowspan="1">
                              {this.state.psychologist.first_name} {this.state.psychologist.last_name} -{' '}
                              {global.UF.tr(this.state.psychologist.specialist_type)}
                           </td>
                           <td class="c32" colspan="1" rowspan="1" id="approved_psychologist">
                              <SubjectApproveButton
                                 className="sbj_btn sbj_key_approved_psychologist"
                                 user={this.props.user}
                                 subject_id={this.state.id}
                                 field={'approved_psychologist'}
                                 updateSubject={this.fetchSpecialistAndDoctors}
                                 subject_type={'appeal'}
                                 patient={this.state.patients}
                                 subject={this.state.subject}
                              />
                           </td>
                        </tr>
                        <tr class="c76">
                           <td class="c38" colspan="2" rowspan="1">
                              {this.state.special_educator_and_rehabilitator.first_name}{' '}
                              {this.state.special_educator_and_rehabilitator.last_name} -{' '}
                              {global.UF.tr(this.state.special_educator_and_rehabilitator.specialist_type)}
                           </td>
                           <td class="c32" colspan="1" rowspan="1" id="approved_special_educator_and_rehabilitator">
                              <SubjectApproveButton
                                 className="sbj_btn sbj_key_approved_special_educator_and_rehabilitator"
                                 user={this.props.user}
                                 subject_id={this.state.id}
                                 field={'approved_special_educator_and_rehabilitator'}
                                 updateSubject={this.fetchSpecialistAndDoctors}
                                 subject_type={'appeal'}
                                 patient={this.state.patients}
                                 subject={this.state.subject}
                              />
                           </td>
                        </tr>
                        <tr class="c82">
                           <td class="c38" colspan="2" rowspan="1">
                              {this.state.doctor.first_name} {this.state.doctor.last_name} -{' '}
                              {this.state.doctor.doctor_type}
                           </td>
                           <td class="c32" colspan="1" rowspan="1" id="approved_doctor">
                              <SubjectApproveButton
                                 className="sbj_btn sbj_key_approved_doctor"
                                 user={this.props.user}
                                 subject_id={this.state.id}
                                 field={'approved_doctor'}
                                 updateSubject={this.fetchSpecialistAndDoctors}
                                 subject_type={'appeal'}
                                 patient={this.state.patients}
                                 subject={this.state.subject}
                              />
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <p class="c2 c28">
                     <span class="c8"></span>
                  </p>
                  <p class="c2">
                     <span class="c8">ДАТУМ И МЕСТО:</span>
                     <span class="c19 c91">____.____._____, ________________</span>
                  </p>
                  <p class="c58 c105">
                     <span class="c46">
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                     </span>
                  </p>
                  <p class="c28 c79">
                     <span class="c12 c19 c68 c108"></span>
                  </p>
                  <div>
                     <p class="c7">
                        <span class="c29"></span>
                     </p>
                     <a id="t.1cd08da199d18584aa8fa93dcef66e1253caa512"></a>
                     <a id="t.13"></a>
                     <table class="c36">
                        <tbody>
                           <tr class="c80">
                              <td class="c45" colspan="1" rowspan="1">
                                 <p class="c28 c58">
                                    <span class="c19 c52"></span>
                                 </p>
                              </td>
                              <td class="c99" colspan="1" rowspan="1">
                                 <p class="c2">
                                    <span class="c110">
                                       МЕЃУНАРОДНА КЛАСИФИКАЦИЈА НА ФУНКЦИОНИРАЊЕ, ПОПРЕЧЕНОСТ И ЗДРАВЈЕ |МКФ &nbsp;
                                       &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                       &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                       &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                       &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; _________________________________
                                    </span>
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <p class="c2 c28">
                        <span class="c29"></span>
                     </p>
                  </div>
               </div>
            </div>
         </Grid>
      );
   }
}

export default withRouter(Appeal);
