import React, { Fragment } from 'react';
import { UF_Form_Cell, UI_Form, UI_Form_Row } from './controls/nx_form_controls';
import Nx_Ctrl_Generic from '../../ui_components/controls/nx_ctrl_generic';
import './nx_form.scss';
import * as superagent from 'superagent';

import Nx_Dialog from '../../ui_components/dialogs/nx_dialog';
import DeleteItemModal from '../../../components/modals/DeleteItemModal';

class NX_Form_Dialog extends React.Component {
   constructor(props) {
      super(props);
      this.dataConfigs = global.UF.data_structure[props.table];
      this.state = {
         showValidations: false,
         deleteItem: null,
         errorHandler: {
            error: false,
            errorMessage: '',
            type: 'error',
            errorTitle: 'Error'
         }
      };
   }

   buttons = this.props.editItem.isNew
      ? [
           {
              label: 'Откажи',
              onClick: () => this.handleClose(),
              variant: 'text'
           },
           {
              label: 'Додади',
              onClick: async () => await this.onSave(),
              variant: 'contained'
           }
        ]
      : [
           {
              label: 'Откажи',
              onClick: () => this.handleClose(),
              variant: 'text'
           },
           {
              label: 'Избриши',
              onClick: () => this.onDeleteItem(this.props.editItem),
              variant: 'text'
           },
           {
              label: 'Сочувај',
              onClick: async () => await this.onSave(),
              variant: 'contained'
           }
        ];

   handleClose = () => {
      this.props.editItem.Reset();
      this.props.onDialogClose();
   };

   renderField = (field, col, index) => {
      let tempField = field.hasOwnProperty('length')
         ? this.dataConfigs.fields[field]
         : { ...this.dataConfigs.fields[field.field], ...field };
      return (
         <Fragment key={index}>
            <UF_Form_Cell cols={col}>
               <Nx_Ctrl_Generic
                  dataItem={this.props.editItem}
                  showValidation={this.state.showValidations}
                  fieldConfig={tempField}
                  onKeyDown={tempField.multiline ? () => {} : this.onKeyDown}
               />
            </UF_Form_Cell>
         </Fragment>
      );
   };

   renderRow = (row, index) => {
      if (row && row.length) {
         return (
            <Fragment key={index}>
               <UI_Form_Row>{row.map((field, index) => this.renderField(field, row.length, index))}</UI_Form_Row>
            </Fragment>
         );
      }
      return null;
   };

   onKeyDown = async (event) => {
      if (event.keyCode == 13) {
         await this.onSave();
      }
   };

   onSave = async () => {
      if (this.props.onBeforeDataItemSave) {
         this.props.onBeforeDataItemSave(this.props.editItem);
      }
      if (Object.keys(this.props.editItem.GetChangedFields()).length) {
         const isValid = await this.props.editItem.Validate();
         if (isValid) {
            if (this.props.onSave) {
               try {
                  await this.props.onSave(this.props.editItem, async () => {
                     this.setState({ showValidations: false });
                     this.handleClose();
                  });
               } catch (err) {
                  console.log(err);
                  global.UF.setAlertVisibility(true, err.toString(), 'error');
                  throw new Error(err.toString());
               }
            } else {
               if (this.props.editItem.hasOwnProperty(`files`)) {
                  if (this.props.editItem.files.length) {
                     this.props.editItem.files.forEach((file, index) => {
                        let newFile = new File([file], `${file.newName}`);
                        superagent
                           .post('/api/saveFiles')
                           .attach('file', newFile)
                           .field('original_name', file.name)
                           .then((response) => {
                              if (index + 1 == this.props.editItem.files.length) {
                                 this.props.editItem.Save(async (data) => {
                                    if (data.hasOwnProperty('error')) {
                                       global.UF.setAlertVisibility(true, data.error, 'error');
                                       return;
                                    } else {
                                       this.setState({ showValidations: false });
                                       this.handleClose();
                                       if (this.props.afterSave) {
                                          await this.props.afterSave(data);
                                       }
                                    }
                                 });
                              }
                           })
                           .catch((error) => {
                              console.log(`error body json stringify ${JSON.stringify(error)}`);
                           });
                     });
                  } else {
                     this.props.editItem.Save(async (data) => {
                        if (data.hasOwnProperty('error')) {
                           global.UF.setAlertVisibility(true, data.error, 'error');
                           return;
                        } else {
                           this.setState({ showValidations: false });
                           this.handleClose();
                           if (this.props.afterSave) {
                              await this.props.afterSave(data);
                           }
                        }
                     });
                  }
               } else {
                  this.props.editItem.Save(async (data) => {
                     if (data.hasOwnProperty('error')) {
                        global.UF.setAlertVisibility(true, data.error, 'error');
                        return;
                     } else {
                        this.setState({ showValidations: false });
                        this.handleClose();
                        if (this.props.afterSave) {
                           await this.props.afterSave(data);
                        }
                     }
                  });
               }
            }
         } else {
            this.setState({ showValidations: true });
         }
      } else {
         global.UF.setAlertVisibility(true, `The item you want to save haven't changed`, 'warning');
      }
   };

   onDeleteItem = (deleteItem) => {
      this.setState({ deleteItem });
   };

   deleteItem = async (deleteItem) => {
      if (this.props.onDelete) {
         await this.props.onDelete(deleteItem, () => {
            this.props.onDialogClose();
         });

         return;
      }
      deleteItem.Delete(async (error) => {
         if (error.hasOwnProperty('error')) {
            global.UF.setAlertVisibility(true, error.error, 'error');
            return;
         }
         this.props.onDialogClose();
      });
   };

   renderDeleteModal = () => {
      return (
         <DeleteItemModal
            open={this.state.deleteItem !== null}
            onClose={() => this.setState({ deleteItem: null })}
            onDelete={() => this.deleteItem(this.state.deleteItem)}
         />
      );
   };

   render() {
      return (
         <>
            {this.state.deleteItem && this.renderDeleteModal()}
            <Nx_Dialog
               title={`${this.props.editItem.isNew ? 'Додади' : 'Промени'} ${
                  this.props.formTitle ? this.props.formTitle : this.props.title
               }`}
               helper={this.props.helper}
               handleClose={this.handleClose}
               buttons={this.buttons}
               open={this.props.open}
            >
               <UI_Form>{this.props.config.rows.map((row, index) => this.renderRow(row, index))}</UI_Form>
            </Nx_Dialog>
         </>
      );
   }
}

export default NX_Form_Dialog;
