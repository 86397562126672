import React from 'react';
import { Dialog, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import UFButton from '../../nix_components/ui_components/controls/nx_button.js';

function DeleteItemModal(props) {
   const deleteItem = async () => {
      await props.onDelete();
   };

   return (
      <Dialog open={props.open} keepMounted onClose={props.onClose} aria-describedby="alert-dialog-slide-description">
         <div className="nx_modal_wrapper">
            <div className="nx_modal_title">
               <Typography variant="h5">Дали сте сигурни дека сакате да продолжите со бришење?</Typography>
            </div>
            <div className="nx_buttons">
               <UFButton onClick={props.onClose} variant={'outlined'} label={'Откажи'} />
               <UFButton onClick={deleteItem} label={'Избриши'} />
            </div>
         </div>
      </Dialog>
   );
}

DeleteItemModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   onDelete: PropTypes.func
};

export default DeleteItemModal;
