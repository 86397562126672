import React, { Component } from 'react';
import Nx_Dialog from '../../../../../nix_components/ui_components/dialogs/nx_dialog';
import Grid from '../../../../../nix_components/ui_components/layouts/Grid/Grid';
import Nx_Ctrl_Select from '../../../../../nix_components/ui_components/controls/nx_ctrl_select';
import { subjectTypes } from '../../../../../nix_components/data/DataReferences';
import withRouter from '../../../../../nix_components/components/withRouter';
import { usersTypes } from '../../../../../nix_components/data/DataReferences';

class AddSubjectModal extends Component {
   constructor(props) {
      super(props);
      this.state = {
         patient_id: props.patient_id,
         subjects: [],
         selected_type: null,
         user_able_to_create_appeal: false
      };

      this.formattedSubjectTypes = Object.entries(subjectTypes).map(([key, value]) => {
         return { id: key, label: value };
      });
   }

   buttons = [
      {
         label: 'Откажи',
         variant: 'text',
         onClick: this.props.handleClose
      },
      {
         label: 'Потврди',
         variant: 'text',
         onClick: () => this.handleAddingSubject()
      }
   ];

   initializing = async () => {
      await this.handleRegionBasedOnUser();
      await this.handleFetchingSubjects();
   };

   componentDidMount = async () => {
      await this.initializing();
   };

   handleRegionBasedOnUser = async () => {
      const { user } = this.props;

      if (usersTypes[user.user_type_id] == 'super_admin') {
         this.setState({ user_able_to_create_appeal: true });
         return;
      }

      const region_id = global.UF.region_id;

      const regions = await global.UF.dataProvider.get_v2(`regions`, {
         id: region_id
      });

      if (!regions.length) {
         global.UF.handleError('Не е пронајден регион со ID ' + region_id);
         return;
      }

      if (regions.length > 1) {
         const hasKT = regions.find((region) => region.code == 'КТ');
         if (hasKT) {
            this.setState({ user_able_to_create_appeal: true });
            return;
         }
      } else {
         const region = regions[0];

         const region_code = region.code;

         if (region_code == 'КТ') {
            this.setState({ user_able_to_create_appeal: true });
            return;
         }
      }
   };

   handleFetchingSubjects = async () => {
      const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
         patient_id: this.state.patient_id
      });

      const first_check_up_subjects = subjects.filter(
         (subject) => subject.subject_type == 'first_check_up' || subject.subject_type == 'archived'
      );

      if (!first_check_up_subjects.length) {
         this.formattedSubjectTypes.forEach((type) => {
            if (type.id != 'first_check_up' && type.id != 'archived') {
               type.disabled = true;
            }
         });
         this.setState({ subjects, selected_type: 'first_check_up' });
         return;
      }

      const active_appeals = subjects.filter(
         (subject) => subject.subject_type == 'appeal' && subject.status != 'final'
      );

      const subject_to_be_appealed = subjects.filter((subject) => {
         return (
            ['first_check_up', 'control_check_up', 'archived'].includes(subject.subject_type) &&
            ['issued', 'final'].includes(subject.status)
         );
      });

      this.formattedSubjectTypes.forEach((type) => {
         if (active_appeals.length && type.id == 'appeal') {
            type.disabled = true;
         }
         if (type.id == 'appeal' && !subject_to_be_appealed.length) {
            type.disabled = true;
         }
         if (type.id == 'first_check_up') {
            type.disabled = true;
         }
         if (type.id == 'appeal' && !this.state.user_able_to_create_appeal) {
            type.disabled = true;
         }
      });
      this.setState({ subjects, selected_type: 'control_check_up' });
   };

   handleSubjectTypeChange = (selected_type) => {
      this.setState({ selected_type });
   };

   handleAddingOrdinalNumber = async (subject_id, subject_type, region_id, patient_id) => {
      try {
         await global.UF.makeRequest_v2(`POST`, `/api/subject/ordinal_number`, {
            region_id: region_id,
            subject_type: subject_type,
            subject_id: subject_id,
            patient_id: patient_id
         });
      } catch (err) {
         global.UF.handleError(err);
      }
   };

   handleAddingSubject = async () => {
      const subject = global.UF.dataProvider.datastructure['subjects'].new();
      subject.subject_type = this.state.selected_type;
      subject.patient_id = this.state.patient_id;
      subject.status = 'in_progress';

      if (this.state.selected_type === 'appeal') {
         const temp_subjects = await global.UF.dataProvider.get_v2(`subjects`, {
            patient_id: this.state.patient_id,
            subject_type: ['first_check_up', 'control_check_up', 'archived'],
            status: ['final', 'issued']
         });

         const last_created_subject = temp_subjects.reduce((next, current) => {
            const next_created_at = new Date(next.created_at);
            const current_created_at = new Date(current.created_at);

            return next_created_at > current_created_at ? next : current;
         }, temp_subjects[0]);

         last_created_subject.status = 'appealed';
         last_created_subject.Save(() => {});
      }

      subject.Save(async (response) => {
         if (response.hasOwnProperty('error')) {
            global.UF.handleError(response.error);
            return;
         }

         const { id } = response;

         if (this.state.selected_type !== 'archived') {
            await this.handleAddingOrdinalNumber(
               id,
               this.state.selected_type,
               this.props.region_id,
               this.state.patient_id
            );
         }

         if (this.state.selected_type === 'appeal') {
            this.props.router.navigate(`/appeal/${this.state.patient_id}/${id}/${subject.subject_type}`);
            return;
         }
         this.props.router.navigate(`/subjects/${this.state.patient_id}/${id}/${subject.subject_type}`);
      });
   };

   render() {
      return (
         <Nx_Dialog
            open={this.props.open}
            handleClose={this.props.handleClose}
            width="750px"
            title={`Каков документ сакате да креирате?`}
            buttons={this.buttons}
         >
            <Grid
               style={{
                  paddingTop: '50px',
                  overflow: 'hidden',
                  justifyItems: 'center'
               }}
            >
               <Nx_Ctrl_Select
                  data={this.formattedSubjectTypes}
                  onChange={this.handleSubjectTypeChange}
                  fieldConfig={{
                     label: 'Каков документ сакате да креирате?',
                     width: '65%'
                  }}
                  value={this.state.selected_type}
               />
            </Grid>
         </Nx_Dialog>
      );
   }
}

export default withRouter(AddSubjectModal);
