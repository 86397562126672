import React, { useState } from 'react';
import NxGridView from '../../../nix_components/components/NxGridView';
import Grid from '../../../nix_components/ui_components/layouts/Grid/Grid';
import UF_Content from '../../../nix_components/components/UF_Content';
import { useNavigate } from 'react-router-dom';
import { usersTypes, subjectStatuses } from '../../../nix_components/data/DataReferences';
import TransferPatientRegion from './components/modals/TransferPatientRegion';
import AddSubjectModal from './components/modals/AddSubjectModal';

export default function Patient({ id, dataItem, user }) {
   const navigate = useNavigate();
   const [buttons, setButtons] = useState({});
   const [tools, setTools] = useState({});
   const [patient, setPatient] = useState(dataItem);
   const [showTransferModal, setShowTransferModal] = useState(false);
   const [showAddSubjectModal, setShowAddSubjectModal] = useState(false);

   global.UF.dataProvider.datastructure['view_subjects'].filters = {
      patient_id: id
   };

   const viewConfig = {
      rows: [
         ['id_number', 'gender'],
         ['first_name', 'fathers_name', 'last_name'],
         ['date_of_birth', 'address', 'city'],
         ['community', 'living_place', 'zip_code'],
         ['region_id', 'birth_place'],
         ['contact_phone', 'contact_email'],
         ['contact_name', 'contact_relationship']
      ]
   };

   const gridProps = {
      fields: ['delovoden_broj', 'subject_type', 'created_by', 'status', 'datum_na_procenka', 'created_at']
   };

   const formConfig = {
      rows: [
         ['patient_id', 'delovoden_broj'],
         // usersTypes[user.user_type_id] === 'super_admin' ? ['datum_na_procenka', {}] : []
         ['datum_na_procenka', {}]
      ]
   };

   const handleFormViewButtons = () => {
      const valid = ['super_admin', 'regional_admin'].includes(usersTypes[user.user_type_id]);

      if (!valid) {
         return {};
      }

      return [
         {
            label: 'Пренос во друг регион',
            variant: 'text',
            onClick: () => setShowTransferModal(true)
         }
      ];
   };

   const handleTransferModalClose = async () => {
      const patients = await global.UF.dataProvider.get_v2(`patients`, {
         id: id
      });
      setPatient(patients[0]);
      setShowTransferModal(false);
   };

   const renderTransferModal = () => {
      return (
         <TransferPatientRegion
            open={showTransferModal}
            handleClose={handleTransferModalClose}
            current_region={global.UF.dataProvider.referenceProvider.getReferenceLabel(`regions`, patient.region_id)}
            patient_id={id}
            user={user}
         />
      );
   };

   const renderAddSubjectModal = () => {
      return (
         <AddSubjectModal
            open={showAddSubjectModal}
            handleClose={() => setShowAddSubjectModal(false)}
            patient_id={id}
            user={user}
            region_id={patient?.region_id}
         />
      );
   };

   const handleSubjectRowClick = (row) => {
      const subject_id = row.id;
      const subject_type = row.row.subject_type;
      if (subject_type === 'appeal') {
         navigate(`/appeal/${id}/${subject_id}/${subject_type}`);
         return;
      }
      navigate(`/subjects/${id}/${subject_id}/${subject_type}`);
   };

   const handleAddingNewSubject = async () => {
      const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
         status: 'in_progress',
         patient_id: id
      });

      if (!subjects.length) {
         setShowAddSubjectModal(true);
         return;
      }

      global.UF.setAlertVisibility(true, 'Веќе постои еден предмет во изработка за овој пациент!', 'warning');
   };

   const handleDeleteSubject = async (dataItem, callback) => {
      const subjects = await global.UF.dataProvider.get_v2(`subjects`, { id: dataItem.id });

      if (!subjects.length) {
         global.UF.setAlertVisibility(true, `Преметот не е пронађен`, 'warning');
         return;
      }

      const subject = subjects[0];

      if (subject.status !== 'in_progress') {
         global.UF.setAlertVisibility(
            true,
            `Преметот е со статус "${
               subjectStatuses[subject.status]
            }". Само предмети со статус "Во изработка" можат да бидат избришани.`,
            'warning'
         );
         return;
      }

      if (subject.subject_type === 'appeal') {
         const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
            patient_id: subject.patient_id,
            subject_type: ['first_check_up', 'control_check_up'],
            status: 'appealed',
            order_by: 'created_at',
            order: 'DESC'
         });

         if (!subjects.length) {
            subject.Delete(() => {
               if (callback) {
                  callback();
               }
            });
            return;
         }

         const appealedSubject = subjects[0];

         appealedSubject.status = 'issued';

         appealedSubject.Save(() => {
            subject.Delete(() => {
               if (callback) {
                  callback();
               }
            });
         });

         return;
      }

      subject.Delete(() => {
         if (callback) {
            callback();
         }
      });

      return;
   };

   const handleSavingSubject = async (dataItem, callback) => {
      const response = await global.UF.makeRequest_v2(`POST`, `/api/subjects/modify`, {
         id: dataItem.id,
         patient_id: dataItem.patient_id,
         delovoden_broj: dataItem.delovoden_broj,
         datum_na_procenka: dataItem.datum_na_procenka
      });

      if (callback) {
         callback();
      }
   };

   return (
      <Grid style={{ alignItems: 'center' }}>
         {showTransferModal && renderTransferModal()}
         {showAddSubjectModal && renderAddSubjectModal()}
         <Grid
            className="patient_wrapper"
            style={{
               gridTemplateColumns: '40% 55%',
               justifyContent: 'space-evenly',
               height: '95%'
            }}
         >
            <NxGridView
               key={patient?.region_id}
               dataItem={patient}
               table={'patients'}
               title={'Пациент'}
               viewConfig={viewConfig}
               buttons={handleFormViewButtons()}
            />

            <UF_Content
               table="view_subjects"
               helper="Пример реден бр. : П01-001/2021"
               title="Преглед и управување со предмети"
               formTitle="Предмет"
               gridProps={gridProps}
               buttons={buttons}
               tools={tools}
               edit={true}
               onAdd={handleAddingNewSubject}
               onDelete={handleDeleteSubject}
               onSave={handleSavingSubject}
               onRowClick={handleSubjectRowClick}
               formConfig={formConfig ? formConfig : {}}
               add={true}
            />
         </Grid>
      </Grid>
   );
}
