import * as React from 'react';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

export default function SubjectInput({ subject_details, field, type, multiline, className, read_only }) {
   const [value, setValue] = React.useState(() => {
      if (subject_details[field]) {
         if (type === 'checkbox') {
            return subject_details[field] == 'true';
         }
         return subject_details[field];
      } else {
         if (type === 'date' && field !== '3_5_date_of_reassessment') {
            return null;
         }
         return '';
      }
   });

   const onChange = (e) => {
      if (type === 'checkbox') {
         const value = e.target.checked;
         subject_details[field] = value;
         setValue(value);
         return;
      }

      if (type === 'date') {
         if (e && e.$d) {
            let date = new Date(e.$d);
            date = moment(date).format('YYYY-MM-DD');
            subject_details[field] = date;
            setValue(date);
         } else {
            subject_details[field] = null;
            setValue(null);
         }
         return;
      }

      if (multiline) {
         const el = e.target;
         el.style.height = '1px';
         el.style.height = 25 + el.scrollHeight + 'px';
      }

      const value = e.target.value;
      subject_details[field] = value;
      setValue(value);
   };

   const renderElement = () => {
      if (multiline) {
         return (
            <textarea
               rows={1}
               className={className}
               onChange={onChange}
               style={{ overflow: 'hidden' }}
               readOnly={read_only}
            >
               {value}
            </textarea>
         );
      }

      if (type === 'date') {
         return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DatePicker
                  inputFormat={'DD-MM-YYYY'}
                  onChange={onChange}
                  value={value}
                  renderInput={(params) => <TextField size={'small'} {...params} sx={{ background: 'white' }} />}
               />
            </LocalizationProvider>
         );
      }

      return (
         <input
            type={type ? type : 'text'}
            className={className}
            value={value}
            checked={type === 'checkbox' ? value == true : undefined}
            onChange={onChange}
            readOnly={read_only}
         />
      );
   };

   return renderElement();
}
